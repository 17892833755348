import { forwardRef, useReducer, useState } from "react";
import React, { useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui icons
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import DataTable from "examples/Tables/DataTable";

// Handler
import ExpressHandler from "handler/expressHandler";
import ExpressKeycloakHandler from "handler/expressKeycloakHandler";

// Data
import getDocumentsTableData from "./data/getDocumentsTableData";
import getJsonBodyImportData from "./data/getJsonBodyImportData";

// Excel package
import * as XLSX from "xlsx";

const FileImport = forwardRef(({ test, ...props }, ref) => {
  const expressHandler = new ExpressHandler();
  const expressKeycloakHandler = new ExpressKeycloakHandler();
  const [dragActive, setDragActive] = useState(false);
  const [hoverActive, setHoverActive] = useState(false);
  const [docColoumnData, setDocColoumnData] = useState(null);
  const [docRowData, setDocRowData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toastSuccess, setToastSuccess] = useState(false);
  const [toastTitle, setToastTitle] = useState("Textfeld leer");
  const [toastContent, setToastContent] = useState("Textfeld hat keinen Inhalt");

  const openToastSuccess = (titel, content) => {
    setToastTitle(titel);
    setToastContent(content);
    setToastSuccess(true);
  };
  const closeToastSuccess = () => setToastSuccess(false);
  const renderSuccess = (
    <MDSnackbar
      color="succcess"
      icon="check"
      title={toastTitle}
      content={toastContent}
      dateTime=""
      open={toastSuccess}
      onClose={closeToastSuccess}
      close={closeToastSuccess}
      bgWhite
    />
  );

  // Function used to import/read the data out of the excel file
  const importData = async (index) => {
    const response_exp_doc = await expressHandler.getRawDataById(documents[index]._id);
    // Parse Excel file
    const workbook = XLSX.read(response_exp_doc.binaryData, { type: 'binary' });

    // Get the first sheet name
    const firstSheetName = workbook.SheetNames[0];

    // Get the worksheet
    const worksheet = workbook.Sheets[firstSheetName];

    // Loop until finding the specified value ("Verantwortlicher Vorname") in the first cell of a row
    let excel_jsonData;
    let excel_rowIndex = 0;
    while (true) {
        const cell = XLSX.utils.encode_cell({ r: excel_rowIndex, c: 0 });
        if (worksheet[cell] && worksheet[cell].v === "Verantwortlicher Vorname") {
            break; // Exit the loop if the value is found
        }
        excel_rowIndex++;
    }

    // Update the range to start from the found row
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    range.s.r = excel_rowIndex;
    worksheet['!ref'] = XLSX.utils.encode_range(range);

    // Convert worksheet data to JSON format
    excel_jsonData = XLSX.utils.sheet_to_json(worksheet);

    // console.log(excel_jsonData);
    // console.log(excel_jsonData[0]["Verantwortlicher Vorname"]);

    // Get the exisiting Data
    const response_implementedFallnummern = await expressHandler.getImportDataFallnummern();
    const implementedFallnummern = [];
    response_implementedFallnummern.forEach(item => {
      implementedFallnummern.push(item.fallnummer)
    });

    // Find the doubled Data
    const doubledData = [];
    excel_jsonData.map(item => {
      if (implementedFallnummern.includes(item.Fallnummer)) {
        doubledData.push(item.Fallnummer);
      }
      return null;
    })
    // TODO Query if doubled Fallnummer should be taken the new or the old one
    const userId = expressKeycloakHandler.getCookie("user_id");
    const department = expressKeycloakHandler.getCookie("department");
    let importDataBody = getJsonBodyImportData(excel_jsonData, userId, department);
    const response_createImportData = await expressHandler.createImportData(importDataBody);
    const response_updateRawData = await expressHandler.updateRawData({importiert: true}, documents[index]._id)
    if (response_updateRawData) {
      const response_express = await expressHandler.getMetadataRawData();
      setDocuments({ type: "LOAD_FILES_FROM_MONGODB", payload: response_express });
      openToastSuccess("Importieren erfolgreich", "Daten wurden erfolgreich eingelesen und gespeichert");
    }
  }

  // Table Content
  const [documents, setDocuments] = useReducer((state, action) => {
    switch (action.type) {
      case "ADD_FILES_TO_INPUT": {
        //TODO check if correct file format is inserted
        // prepare data to display in table
        const { columns: colData, rows: rowData } = getDocumentsTableData([
          ...state,
          ...action.payload,
        ], importData, loading, setLoading, false);
        setDocColoumnData(colData);
        setDocRowData(rowData);
        return [...state, ...action.payload];
      }
      case "LOAD_FILES_FROM_MONGODB": {
        // page load (retrieve files from mongoDB)
        const { columns: colData, rows: rowData } = getDocumentsTableData(action.payload, importData, loading, setLoading, false);
        setDocColoumnData(colData);
        setDocRowData(rowData);
        return [...state, ...action.payload];
      }
      case "DELETE_FILE": {
        // TODO removing
        // TODO toast successfully removed
        console.log("Successfully removed");
      }
    }
  }, []);
  // page load
  const onPageLoad = async () => {
    if (documents.length === 0) {
      const response_express = await expressHandler.getMetadataRawData();
      setDocuments({ type: "LOAD_FILES_FROM_MONGODB", payload: response_express });
    }
  };
  useEffect(() => {
    onPageLoad();
  }, []);

  // Function to read file as binary data
  const readFileAsBinary = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        let readedData = XLSX.read(event.target.result, {type: 'binary'});
        const wsname = readedData.SheetNames[0];
        const ws = readedData.Sheets[wsname];
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsBinaryString(file);
    });
  };

  // handle drag events
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleMouseEnter = (e) => {
    e.preventDefault();
    setHoverActive(true);
  };
  const handleMouseLeave = (e) => {
    e.preventDefault();
    setHoverActive(false);
  };

  // triggers when file is dropped
  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    // validate file type
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const files = Array.from(e.dataTransfer.files);
      // TODO: Further file validation
      const file = e.dataTransfer.files[0];
      const fileBinary = await readFileAsBinary(file);

      const userId = expressKeycloakHandler.getCookie("user_id");
      const department = expressKeycloakHandler.getCookie("department");
      // this is the body how it will be saved in mongodb
      const mongoStorageBody = [
        {
          name: file.name,
          size: file.size,
          type: file.type,
          binaryData: fileBinary,
          importiert: false,
          userId: userId,
          department: department,
        },
      ];
      // save document to mongodb
      const response_express = await expressHandler.createRawData(mongoStorageBody[0]);
      setDocuments({ type: "ADD_FILES_TO_INPUT", payload: [response_express] });
      // TODO toast successfully uploaded
    }
  };

  return (
    <MDBox display="flex" justifyContent="center" flexDirection="column" rowGap={1} py={2} px={2}>
      {renderSuccess}
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        height="130px"
        bgColor={dragActive || hoverActive ? "secondary" : "light"}
        border={dragActive || hoverActive ? "none" : "1px dashed black"}
        borderRadius="lg"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        style={{ cursor: "pointer" }}
      >
        <CloudUploadIcon fontSize="large" />
        <MDTypography>Drag and drop</MDTypography>
      </MDBox>
      {docColoumnData && (
        <MDBox pt={3}>
          <DataTable
            table={{ columns: docColoumnData, rows: docRowData }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          />
        </MDBox>
      )}
    </MDBox>
  );
});

// Setting default values for the props of MDInput
FileImport.defaultProps = {
  test: false,
};

// Typechecking props for the MDInput
FileImport.propTypes = {
  test: PropTypes.bool,
};

export default FileImport;
