import { useState, useEffect } from "react";
import { toast } from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";

// Own components
import InputTextCard from "./components/InputTextCard";
import InputTextCardOriginalText from "./components/InputTextCardOriginalText";
import HistoryCard from "./components/HistoryCard";
import ShowCard from "./components/ShowCard";
import ShowCardKI from "./components/ShowCardKI";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Handler
import ExpressHandler from "handler/expressHandler";
import LlmBackendHandler from "handler/llmBackendHandler";
import RbBackendHandler from "handler/rbBackendHandler";
import FastAPIHandler from "handler/fastapiHandler";
import ExpressKeycloakHandler from "handler/expressKeycloakHandler";
import TokenizerBackendHandler from "handler/tokenizerBackendHandler";

function Deidentification() {
  const [inputText, setInputText] = useState("");
  const [inputPostprocessingText, setInputPostprocessingText] = useState("");
  const [kiText, setKiText] = useState("");
  const [history, setHistory] = useState([]);
  const [historyOrginalTxt, setHistoryOrginalTxt] = useState("");
  const [historyKiTxt, setHistoryKiTxt] = useState("");
  const [historyPostprocessingTxt, setHistoryPostprocessingTxt] = useState("");
  const [txtInputDisable, setTxtInputDisable] = useState([false, true]);
  const [buttonsVisibility, setButtonsVisibility] = useState([true, false]);
  const [time, setTime] = useState({ minutes: 0, seconds: 0 });
  const [kiInference, setKiInference] = useState(null);
  const [tokens, setTokens] = useState([]);
  // const [taggedTokens, setTaggedTokens] = useState([]);
  const [numberOfRuns, setNumberOfRuns] = useState(0);
  const [maxNumberOfRuns, setMaxNumberOfRuns] = useState(10);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(1);
  const [enableTabValue, setEnableTabValue] = useState(true);
  const [toastWarning, setToastWarning] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [toastTitle, setToastTitle] = useState("Textfeld leer");
  const [toastContent, setToastContent] = useState("Textfeld hat keinen Inhalt");


  const expressHandler = new ExpressHandler();
  const expressKeycloakHandler = new ExpressKeycloakHandler();
  const fastapiHandler = new FastAPIHandler();
  const llmBackendHandler = new LlmBackendHandler();
  const rbBackendHandler = new RbBackendHandler();
  const tokenizerBackendHandler = new TokenizerBackendHandler();

  const handleTextInputChange = (e) => {
    if (txtInputDisable[1]) {
      setInputText(e.target.value);
    }
  };
  const handlePostprocessingInputChange = (e) => {
    if (txtInputDisable[0]) {
      setInputPostprocessingText(e.target.value);
    }
  };

  // Toast Objects + Logic
  const openToastWarning = (titel, content) => {
    setToastTitle(titel);
    setToastContent(content);
    setToastWarning(true);
  };
  const closeToastWarning = () => setToastWarning(false);
  const openToastError = (titel, content) => {
    setToastTitle(titel);
    setToastContent(content);
    setToastError(true);
  };
  const closeToastError = () => setToastError(false);

  const renderWarning = (
    <MDSnackbar
      color="warning"
      icon="notifications"
      title={toastTitle}
      content={toastContent}
      dateTime=""
      open={toastWarning}
      onClose={closeToastWarning}
      close={closeToastWarning}
      bgWhite
    />
  );

  const renderError = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={toastTitle}
      content={toastContent}
      dateTime=""
      open={toastError}
      onClose={closeToastError}
      close={closeToastError}
      bgWhite
    />
  );

  const onPageLoad = async () => {
    await expressKeycloakHandler.checkRefreshToken();
    const resp_numberRuns = await expressKeycloakHandler.getTokenCount();
    if (resp_numberRuns != undefined) {
      setNumberOfRuns(parseInt(resp_numberRuns.tokens_used[0]));
      setMaxNumberOfRuns(parseInt(resp_numberRuns.maxTokens[0]));
      // If max runs are filled deactivate
      if (parseInt(resp_numberRuns.tokens_used[0]) >= parseInt(resp_numberRuns.maxTokens[0])) {
        setTxtInputDisable([false, true]);
        setButtonsVisibility([true, false]);
      }
    }

    const response_hist = await expressHandler.getFreiTexteHistory();
    const userId = await expressKeycloakHandler.getCookie("user_id");
    const user_hist = response_hist.filter((item) => ( item.userId === userId ));
    user_hist.reverse();

    setHistory(user_hist);
  }

  useEffect(() => {
    onPageLoad();
    const interval = setInterval(() => {
      setTime(prevTime => {
        const newSeconds = prevTime.seconds + 1;
        const newMinutes = prevTime.minutes + Math.floor(newSeconds / 60);
        return {
          minutes: newMinutes,
          seconds: newSeconds % 60
        };
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const resetTimer = async() => {
    setTime({ minutes: 0, seconds: 0 });
  }

  const startDeidentifyRB = async () => {
    if (inputText !== "") {
      // Set loading animation 
      setLoading(true);
      setEnableTabValue(false);
      setTxtInputDisable([true, true]);

      // Check max runs limit is not reached
      const resp_numberRuns = await expressKeycloakHandler.getTokenCount();
      if (resp_numberRuns == undefined) {
        setTxtInputDisable([false, true]);
        setButtonsVisibility([true, false]);
        openToastWarning("Fehler aufgetreten", "Probieren sie es nochmal");
        setLoading(false);
        setEnableTabValue(true);
        return null
      }
      // If max runs are filled deactivate
      if (parseInt(resp_numberRuns.tokens_used[0]) >= parseInt(resp_numberRuns.maxTokens[0])) {
        setTxtInputDisable([false, true]);
        setButtonsVisibility([true, false]);
        openToastWarning("Limit erreicht", "Limit der Anonymisierungen erreicht");
        setLoading(false);
        setEnableTabValue(true);
        return null
      }
      resetTimer();
      setButtonsVisibility([false, false]);

      const userId = expressKeycloakHandler.getCookie("user_id");
      const department = expressKeycloakHandler.getCookie("department");

      let body_01 = {
        txt: inputText,
        userId: userId,
        department: department,
      };
      
      // Tokenize original text
      const tokens = await tokenizerBackendHandler.tokenize(inputText);
      // console.log(tokenized);

      // // Save original text
      // const response_savedTxtInput = await expressHandler.createTxt(body_01);
      // const savedTxtInputIdBody = { mongodb_txtinput_id: response_savedTxtInput._id };

      // // const tokens = inputText.match(/[\w]+|[.,!?:]/g);
      // // Tokenize original text
      // const responseTokenizedText = await fastapiHandler.jslTokenize(savedTxtInputIdBody);

      // // Check if backend (JSL) response is not undefined
      // if(responseTokenizedText == undefined) {
      //   const response_express_deleteTxtInput = await expressHandler.deleteTxtInput(response_savedTxtInput._id);
      //   setLoading(false);
      //   return null;
      // }
      // // Extract the tokens out of the JSL response
      // const words = responseTokenizedText.tokens;
      // let tokens = [];
      // words.forEach((word) => {
      //   tokens.push(word.token);
      // });

      // Rule based Method
      const body = {
        sentence: inputText,
        tokens: tokens,
      }
      const responseRbBackend = await rbBackendHandler.rbNer(body);
      const resp_incrementRuns = await expressKeycloakHandler.incrementToken();

      // const taggedList = responseRbBackend.annotation;
      // responseRbBackend.annotation.forEach((entity, index) => {
      //   if (entity !== "o") {
      //     taggedList[index] = "<"+entity+">";
      //   }
      // })

      // setTaggedTokens(taggedList);
      setKiText(responseRbBackend.obfuscated_sentence);
      setInputPostprocessingText(responseRbBackend.obfuscated_sentence);
      setKiInference(responseRbBackend);
      // setTokens(words);
      setTokens(tokens);
      setNumberOfRuns(parseInt(resp_numberRuns.tokens_used[0]) + 1);
      setTxtInputDisable([true, false]);
      setButtonsVisibility([false, true]);
      setLoading(false);
      await updateHistory();
    } else {
      openToastWarning("Textfeld leer", "Textfeld hat keinen Inhalt");
    }
  }
  const startDeidentifyLLM = async () => {
    if (inputText !== "") {
      // Set loading animation 
      setLoading(true);
      setEnableTabValue(false);
      setTxtInputDisable([true, true]);
      
      // Check max runs limit is not reached
      const resp_numberRuns = await expressKeycloakHandler.getTokenCount();

      if (resp_numberRuns == undefined) {
        setTxtInputDisable([false, true]);
        setButtonsVisibility([true, false]);
        openToastWarning("Fehler aufgetreten", "Probieren sie es nochmal");
        setLoading(false);
        setEnableTabValue(true);
        return null
      }
      // If max runs are filled deactivate
      if (parseInt(resp_numberRuns.tokens_used[0]) >= parseInt(resp_numberRuns.maxTokens[0])) {
        setTxtInputDisable([false, true]);
        setButtonsVisibility([true, false]);
        openToastWarning("Limit erreicht", "Limit der Anonymisierungen erreicht");
        setLoading(false);
        setEnableTabValue(true);
        return null
      }
      resetTimer();
      setButtonsVisibility([false, false]);
      try {
        
        // Fetch data from model 2
        const responseLlmBackend = await llmBackendHandler.deidLlmBackend(inputText, "adrienbrault/nous-hermes2pro-llama3-8b:q8_0", "b0", "deidentification");


        // const body_02 = {
        //   input_text: inputText,
        //   step: "word_tokenizer"
        // }

        // const tokens = await tokenizerBackendHandler.tokenize(body_02);

        // console.log(tokens);
        // const taggedList = [...taggedTokens];

        // responseLlmBackend.name.forEach((item) => {
        //   tokens.forEach((tok, index) => {
        //     const words = item.split(/\s+/);
        //     words.forEach((word, wordIndex) => {
        //       if (word === tok) {
        //         taggedList[index] = "<NAME>";
        //       }
        //     })
        //   })
        // })
        // responseLlmBackend.age.forEach((item) => {
        //   tokens.forEach((tok, index) => {
        //     const words = item.split(/\s+/);
        //     words.forEach((word, wordIndex) => {
        //       if (word === tok) {
        //         taggedList[index] = "<AGE>";
        //       }
        //     })
        //   })
        // })
        // responseLlmBackend.location.forEach((item) => {
        //   tokens.forEach((tok, index) => {
        //     const words = item.split(/\s+/);
        //     words.forEach((word, wordIndex) => {
        //       if (word === tok) {
        //         taggedList[index] = "<LOCATION>";
        //       }
        //     })
        //   })
        // })
        // responseLlmBackend.date.forEach((item) => {
        //   tokens.forEach((tok, index) => {
        //     const words = item.split(/\s+/);
        //     words.forEach((word, wordIndex) => {
        //       if (word === tok) {
        //         taggedList[index] = "<DATE>";
        //       }
        //     })
        //   })
        // })

        const responseAnonymLlmBackend = await llmBackendHandler.anonymLlmBackend(inputText, responseLlmBackend);
        console.log(responseAnonymLlmBackend);
        console.log(responseLlmBackend);
        const resp_incrementRuns = await expressKeycloakHandler.incrementToken();

        // setTaggedTokens(taggedTokens);
        setKiText(responseAnonymLlmBackend);
        setInputPostprocessingText(responseAnonymLlmBackend);
        setKiInference(responseLlmBackend);
        setTokens([]);
        setNumberOfRuns(parseInt(resp_numberRuns.tokens_used[0]) + 1);
        setTxtInputDisable([true, false]);
        setButtonsVisibility([false, true]);
        setLoading(false);
      } catch (error) {
        // Set loading animation 
        setLoading(false);
        setButtonsVisibility([true, false]);
        setEnableTabValue(true);
        openToastError("Backend nicht erreichbar", "Backend Model 2 antwortet nicht");
      }
    }
    else {
      openToastWarning("Textfeld leer", "Textfeld hat keinen Inhalt");
    }
  };

  const reset = async() => {
    const userId = expressKeycloakHandler.getCookie("user_id");
    const department = expressKeycloakHandler.getCookie("department");
    
    let kiInf = kiInference;
    let modelUsed = "llm";

    if (tabValue== 0) {
      modelUsed = "ruleBased";
    } else {
      kiInf.kiText = kiText;
    }

    const body = {
      input: inputText,
      tokens: tokens,
      aiInference: [kiInf],
      processedInference: inputPostprocessingText,
      userId: userId,
      department: department,
      time: [time],
      modelUsed: modelUsed,
    }
    // Save data in mongodb
    const response = await expressHandler.createFreiTexteHistory(body);
    const response_hist = await expressHandler.getFreiTexteHistory();
    const user_hist = response_hist.filter((item) => ( item.userId === userId ));
    user_hist.reverse();
    
    setHistory(user_hist);
    setInputText("");
    setInputPostprocessingText("");
    setKiText("");
    setTxtInputDisable([false, true]);
    setButtonsVisibility([true, false]);
    setEnableTabValue(true);
  }

  const updateHistory = async () => {
    const response_hist = await expressHandler.getFreiTexteHistory();
    const userId = await expressKeycloakHandler.getCookie("user_id");
    const user_hist = response_hist.filter((item) => ( item.userId === userId ));
    user_hist.reverse();

    setHistory(user_hist);
  }

  const clickOnHistCard = (index) => {
    setHistoryOrginalTxt(history[index].input);
    setHistoryPostprocessingTxt(history[index].processedInference);
    if (history[index].modelUsed === "ruleBased") {
      setHistoryKiTxt(history[index].aiInference[0].obfuscated_sentence);
    } else {
      setHistoryKiTxt(history[index].aiInference[0].kiText);
    }
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {renderWarning}
      {renderError}
      <MDBox>
        <Grid container spacing={5} rowSpacing={3}>
          <InputTextCardOriginalText
            text={inputText}
            header="Originaler Text"
            handleTextInputChange={handleTextInputChange}
            txtInputDisable={txtInputDisable[0]}
            tabValue={tabValue}
            setTabValue={setTabValue}
            enableTabValue={enableTabValue} />
          <ShowCardKI
            text={kiText}
            header="Ergebnis der KI"
            loading={loading}
            startModel1={startDeidentifyRB}
            startModel2={startDeidentifyLLM}
            tabValue={tabValue}
            enableTabValue={enableTabValue}
            buttonVisibility={buttonsVisibility[0]}
            numberOfRuns={numberOfRuns}
            maxNumberOfRuns={maxNumberOfRuns} />
          <InputTextCard
            text={inputPostprocessingText}
            header="Nachbearbeitung"
            handleTextInputChange={handlePostprocessingInputChange}
            txtInputDisable={txtInputDisable[1]}
            buttonFunction={reset}
            buttonVisibility={buttonsVisibility[1]} />
          <HistoryCard
            header="Verlauf"
            history={history}
            clickOnHistCard={clickOnHistCard}/>
          <ShowCard
            text={historyOrginalTxt}
            header="Originaler Text"
            loading={false} />
          <ShowCard
            text={historyKiTxt}
            header="Ergebnis der KI"
            loading={false} />
          <ShowCard
            text={historyPostprocessingTxt}
            header="Gesamtergebnis"
            loading={false} />
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Deidentification;
