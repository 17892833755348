// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";

export default function getImportData(data, impData) {
  const rows = [];
  const importData = async (docRowNumber) => {
    await impData(docRowNumber);
  }
  for (let i = 0; i < data.length; i++) {
    rows.push({
      fallnummer: (
        <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
          {data[i].fallnummer}
        </MDTypography>
      ),
      // systemstatus: (
      //   <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
      //     {data[i].systemstatus}
      //   </MDTypography>
      // ),
      // bearbeitungsstatus: (
      //   <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
      //     {data[i].bearbeitungsstatus}
      //   </MDTypography>
      // ),
      titel: (
        <MDBox display="flex" alignItems="center" style={{ minWidth: "500px", maxWidth: "500px", minHeight: "40px", maxHeight: "40px"}}>
          <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium" style={{ textAlign: 'left', width: '100%' }}>
            {data[i].titel}
          </MDTypography>
        </MDBox>
      ),
      deid: (
        <MDBox display="flex" alignItems="center">
          {!data[i].deidentifiziert ? (
            <MDButton variant="gradient" color="dark" onClick={() => importData(i)}>
              Anonymisieren
            </MDButton>
          ) : (
            <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
              Schon Anonymisiert
            </MDTypography>
          )}
        </MDBox>
      ),
    });
  }
  return {
    columns: [
      { Header: "Fallnummer", accessor: "fallnummer", align: "center" },
      // { Header: "Systemstatus", accessor: "systemstatus", align: "center" },
      // { Header: "Bearbeitungsstatus", accessor: "bearbeitungsstatus", align: "center" },
      { Header: "Titel", accessor: "titel", align: "center" },
      { Header: "Aktion", accessor: "deid", align: "center" },
    ],
    rows: rows,
  };
}