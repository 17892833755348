import React, { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Own components
import DeidProzess from "./components/deidProzess/deidProzess";
import Popup from "examples/Popup";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Handler
import ExpressHandler from "handler/expressHandler";
import ExpressKeycloakHandler from "handler/expressKeycloakHandler";

function Lc_research() {
  const expressHandler = new ExpressHandler();
  const expressKeycloakHandler = new ExpressKeycloakHandler();

  // Variables for deidentification
  const [deidentifyTitle, setDeidentifyTitle] = useState("");
  const [deidentifyFallnummer, setDeidentifyFallnummer] = useState("");
  const [inputText, setInputText] = useState("");
  const [editedText, setEditedText] = useState("");
  const [importedData, setImportedData] = useState("");
  const [importedDataNumber, setImportedDataNumber] = useState(0);
  const [importedDataId, setImportedDataId] = useState("");
  const [deidentificationVisibility, setDeidentificationVisibility] = useState(false);
  const [numberOfCases, setNumberOfCases] = useState(5);
  const [randomChoosenDataset, setRandomChoosenDataset] = useState([]);
  const [littleOverviewArray, setLittleOverviewArray] = useState([]);
  const [temporarySaving, setTemporarySaving] = useState(null);
  const [continueStudyDisabled, setContinueStudyDisabled] = useState(true);
  const [popupActive, setPopupActive] = useState(false);
  const [toastSuccess, setToastSuccess] = useState(false);
  const [toastTitle, setToastTitle] = useState("Textfeld leer");
  const [toastContent, setToastContent] = useState("Textfeld hat keinen Inhalt");

  const openToastSuccess = (titel, content) => {
    setToastTitle(titel);
    setToastContent(content);
    setToastSuccess(true);
  };
  const closeToastSuccess = () => setToastSuccess(false);
  const renderSuccess = (
    <MDSnackbar
      color="succcess"
      icon="check"
      title={toastTitle}
      content={toastContent}
      dateTime=""
      open={toastSuccess}
      onClose={closeToastSuccess}
      close={closeToastSuccess}
      bgWhite
    />
  );

  const onPageLoad = async () => {
    const userId = expressKeycloakHandler.getCookie("user_id")
    const resp_ImpData = await expressHandler.getImportData();
    const resp_currStudy = await expressHandler.getCurrentStudy(userId);
    if (resp_currStudy.length > 0) {
      setTemporarySaving(resp_currStudy[0]);
      setContinueStudyDisabled(false);
    }
    setImportedData(resp_ImpData);
  };
  useEffect(() => {
    onPageLoad();
  }, []);

  const getData = () => {
    const dataset = [];
    const randomIndexes = [];
    while (randomIndexes.length < numberOfCases) {
      const randomIndex = Math.floor(Math.random() * importedData.length);
      if (!randomIndexes.includes(randomIndex)) {
        randomIndexes.push(randomIndex);
      }
    }
    randomIndexes.map((ind) => {
      dataset.push(importedData[ind]);
    })
    return dataset;
  };

  const chooseData = (num) => {
    setImportedDataId(temporarySaving.impDataId[num]);
    setImportedDataNumber(num);
    setDeidentifyTitle(temporarySaving.impDataTitle[num]);
    setDeidentifyFallnummer(temporarySaving.impDataFallnummer[num]);
    setInputText(temporarySaving.impDataOriginalText[num]);
    setEditedText(temporarySaving.impDataEditedText[num]);
    return [temporarySaving.impDataOriginalText[num], num];
  }

  const nextData = () => {
    let inTxt = "";
    let num = 0;
    if (importedDataNumber === temporarySaving.impDataId.length-1) {
      [inTxt, num] = chooseData(0);
    } else {
      [inTxt, num] = chooseData(importedDataNumber+1);
    }
    return [inTxt, num]
  }
  const prevData = () => {
    let inTxt = "";
    let num = 0;
    if (importedDataNumber === 0) {
      [inTxt, num] = chooseData(temporarySaving.impDataId.length-1);
    } else {
      [inTxt, num] = chooseData(importedDataNumber-1);
    }
    return [inTxt, num]
  }
  const openPopup = async() => {
    const userId = expressKeycloakHandler.getCookie("user_id")
    const resp_currStudy = await expressHandler.getCurrentStudy(userId);
    if (resp_currStudy.length > 0) {
      setPopupActive(true);
    } else {
      startStudie();
    }
  }

  const closePopup = () => {
    setPopupActive(false);
  }

  const startStudie = async () => {
    setImportedDataNumber(0);
    setPopupActive(false);
    const userId = expressKeycloakHandler.getCookie("user_id");
    const department = expressKeycloakHandler.getCookie("department");
    // get random dataset with the choosen number of cases
    const dataset = getData();
    
    // Deleting temporary saving if there is any in mongoDB
    const resp_currStudy = await expressHandler.getCurrentStudy(userId);
    if (resp_currStudy.length > 0) {
      resp_currStudy.forEach(async (item) => {
        const resp_deleteCurrStudy = await expressHandler.deleteCurrentStudy(item._id);
      })
    }

    // Preparing for temporary saving
    let impDataId = [];
    let impDataTitle = [];
    let impDataFallnummer = [];
    let impDataOriginalText = [];
    let impDataEditedText = [];
    let Tokens = [];
    let TaggedManual = [];
    let TaggedModel1 = [];
    let TaggedModel2 = [];
    let ObfTextModel1 = [];
    let ObfTextModel2 = [];
    let humanFeedbackObf=[];
    dataset.forEach((data) => {
      impDataId.push(data._id);
      impDataTitle.push(data.titel);
      impDataFallnummer.push(data.fallnummer);
      impDataOriginalText.push(data.originalFallbeschreibung);
      impDataEditedText.push(data.bearbeitetFallbeschreibung);
      Tokens.push([]);
      TaggedManual.push([]);
      TaggedModel1.push([]);
      TaggedModel2.push([]);
      ObfTextModel1.push("");
      ObfTextModel2.push("");
      humanFeedbackObf.push([[false, false], [false, false]]);
    })

    const tmpSaving = {
      impDataId: impDataId,
      impDataTitle: impDataTitle,
      impDataFallnummer: impDataFallnummer,
      impDataOriginalText: impDataOriginalText,
      impDataEditedText: impDataEditedText,
      Tokens: Tokens,
      TaggedManual: TaggedManual,
      TaggedModel1: TaggedModel1,
      TaggedModel2: TaggedModel2,
      ObfTextModel1: ObfTextModel1,
      ObfTextModel2: ObfTextModel2,
      humanFeedbackObf: humanFeedbackObf,
      userId: userId,
      department: department
    }
    const currStudy = await expressHandler.createCurrentStudy(tmpSaving);

    setTemporarySaving(currStudy);
    setRandomChoosenDataset(currStudy);
    setLittleOverviewArray(Array.from({ length: dataset.length }).fill(false));
    setImportedDataId(dataset[importedDataNumber]._id);
    setDeidentifyTitle(dataset[importedDataNumber].titel);
    setDeidentifyFallnummer(dataset[importedDataNumber].fallnummer);
    setInputText(dataset[importedDataNumber].originalFallbeschreibung);
    setEditedText(dataset[importedDataNumber].bearbeitetFallbeschreibung);

    // Make the deidentification screen visible
    setDeidentificationVisibility(true);
  } 
  const handleNumberInputChange = (e) => {
    setNumberOfCases(e.target.value);
  }
  const continueStudie = async () => {
    const userId = expressKeycloakHandler.getCookie("user_id");
    // get study
    const resp_currStudy = await expressHandler.getCurrentStudy(userId);

    setTemporarySaving(resp_currStudy[0]);
    setRandomChoosenDataset(resp_currStudy[0]);
    setLittleOverviewArray(Array.from({ length: resp_currStudy[0].impDataId.length }).fill(false));
    setImportedDataId(resp_currStudy[0].impDataId[importedDataNumber]);
    setDeidentifyTitle(resp_currStudy[0].impDataTitle[importedDataNumber]);
    setDeidentifyFallnummer(resp_currStudy[0].impDataFallnummer[importedDataNumber]);
    setInputText(resp_currStudy[0].impDataOriginalText[importedDataNumber]);
    setEditedText(resp_currStudy[0].impDataEditedText[importedDataNumber]);

    // Make the deidentification screen visible
    setDeidentificationVisibility(true);
  } 
  return (
    <DashboardLayout>
      {renderSuccess}
      {popupActive && 
        <Popup 
          title="Alter Studienfortschritt geht verloren"
          content="Wenn Sie die Studie starten geht die laufende Studie verloren"
          buttonLableLeft="Studie starten"
          buttonLableRight="Nicht starten"
          buttonFunctionLeft={startStudie}
          buttonFunctionRight={closePopup}
        />
      }
      <DashboardNavbar />
      <MDBox>
        <Grid container spacing={5} rowSpacing={5}>
          <Grid item xs={12}>
            {deidentificationVisibility ? (
              <DeidProzess
                title={deidentifyTitle}
                fallnummer={deidentifyFallnummer}
                inputText={inputText}
                editedText={editedText}
                nextData={nextData}
                prevData={prevData}
                chooseData={chooseData}
                overviewArray={littleOverviewArray}
                setOverviewArray={setLittleOverviewArray}
                importedDataNumber={importedDataNumber}
                setImportedDataNumber={setImportedDataNumber}
                importedDataId={importedDataId}
                setDeidentificationVisibility={setDeidentificationVisibility}
                temporarySaving={temporarySaving}
                setTemporarySaving={setTemporarySaving}
                setContinueStudyDisabled={setContinueStudyDisabled}
                openToastSuccess={openToastSuccess}
              />
            ) : (
              <MDBox my={30} display="flex" alignItems="center" justifyContent="center" flexDirection="column" gap={3}>
                <MDInput 
                  label="Zahl an zu bearbeiteten Fälle"
                  value={numberOfCases}
                  onChange={(e) => handleNumberInputChange(e)}
                  style={{ minWidth:"280px", maxWidth:"280px" }}
               />
                <MDButton variant="gradient" color="dark" onClick={() => openPopup()} style={{ fontSize:"20px", minWidth:"280px", maxWidth:"280px" }}>
                  Studie Starten
                </MDButton>
                <MDButton variant="gradient" color="dark" onClick={() => continueStudie()} style={{ fontSize:"20px", minWidth:"280px", maxWidth:"280px" }} disabled={continueStudyDisabled}>
                  Studie Fortsetzen
                </MDButton>
              </MDBox>
            )}
            
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Lc_research;
