import { createContext, useContext, useState, useEffect } from "react";
import { useCookiesContext } from "contextCookies";
import KeycloakService from "services/keycloak";

const AuthContext = createContext();
export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [tokens, setTokens] = useState({ accessToken: null, refreshToken: null });
  const [userInfo, setUserInfo] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const { getCookie, setCookie, deleteCookie } = useCookiesContext();

  const keycloakService = new KeycloakService();

  // Load shopping cart from cookies on initialization
  useEffect(() => {
    const initializeAuth = async () => {
      try {
        const savedTokens = await getCookie("tokens");
        const savedUserInfo = await getCookie("userInfo");

        if (savedTokens !== null) {
          const parsedTokens = JSON.parse(savedTokens);
          
          setTokens(parsedTokens);
          if (savedUserInfo) setUserInfo(JSON.parse(savedUserInfo));
          initialCheckRefreshUser(parsedTokens);
        }
      } catch (error) {
        console.error("Failed to initialize auth:", error);
      } finally {
        setTimeout(function () {
          setIsInitialized(true);
        }, 500);
      }
    };

    initializeAuth();
  }, []);

  const updateCookie = (cookieName, data, timeInDays) => {
    setCookie(cookieName, JSON.stringify(data),  timeInDays * 24 * 60 * 60 * 1000);
  }

  const loginUser = (newTokens, newInfo) => {
    setTokens(newTokens);
    setUserInfo(newInfo);
    updateCookie("tokens", newTokens, 1);
    updateCookie("userInfo", newInfo, 1);
  }

  const initialCheckRefreshUser = async (initTokens) => {
    const newTokens = await keycloakService.checkRefreshToken(initTokens);
    if (newTokens != null) {
      setTokens(newTokens);
      updateCookie("tokens", newTokens, 1);
      updateCookie("userInfo", userInfo, 1);

      if (window.location.href.endsWith("/#/sign-in")) {
        window.location.href = window.location.origin + "/#/einfuehrung";
      }
      return true;
    } else {
      window.location.href = window.location.origin + "/#/sign-in";
    }
  };

  const checkRefreshUser = async () => {
    if (isInitialized) {
      const newTokens = await keycloakService.checkRefreshToken(tokens);
      
      if (newTokens !== null) {
        setTokens(newTokens);
        updateCookie("tokens", newTokens, 1);
        updateCookie("userInfo", userInfo, 1);

        return true;
      } else {
        logoutUser();
        return false
      }
    }
  }

  const logoutUser = async () => {
    if (tokens.refreshToken !== null) {
      const logout = await keycloakService.logout({ tokens });
      setTokens({ accessToken: null, refreshToken: null });
    }
    setUserInfo(null);
    deleteCookie("tokens");
    deleteCookie("userInfo");
    const currentUrl = `${window.location.origin}/#/sign-in`;
    
    if (window.location.href !== currentUrl) {
      window.location.href = currentUrl;
    }
  }
  return (
    <AuthContext.Provider
      value={{
        tokens,
        userInfo,
        isInitialized,
        loginUser,
        checkRefreshUser,
        logoutUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};