/**
=========================================================
* Material Dashboard 3 PRO React - v2.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 3 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 3 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";

// Own Components
import ProtectedContent from "../components/protectContent";

// Context
import { useAuthContext } from "contextAuth";

function Introduction() {


  const { checkRefreshUser } = useAuthContext();

  const onPageLoad = async () => {
    const logInDataFound = await checkRefreshUser();
  }
  useEffect(() => {
    onPageLoad();
  }, []);

  const navToStudie = () => {
    window.location.href = window.location.origin + "/#/studie";
  }
  return (
    <DashboardLayout>
      <ProtectedContent>
        <DashboardNavbar />
        <Grid container spacing={3} justifyContent="center" alignItems="center" >
          <Grid item xs={6} md={6} lg={6}>
          <Card>
              <MDBox p={4} textAlign="center">
                <MDTypography variant="h4" fontWeight="bold" gutterBottom>
                  Willkommen zur Studie zur Anonymisierung mittels KI
                </MDTypography>
                <MDTypography variant="body1" gutterBottom>
                  Diese Studie anonymisiert Freitext automatisch und ermöglicht Ihnen, die Ergebnisse zu überprüfen.
                  Der Prozess besteht aus drei Schritten:
                </MDTypography>
                <MDBox textAlign="left" mt={5}>
                  <MDTypography variant="h5" fontWeight="bold">
                    1. Eingabe des Originaltextes
                  </MDTypography>
                  <MDTypography ml={2} variant="body2" gutterBottom>
                    Geben Sie Ihren Text in das Textfeld ein und klicken Sie auf „Anonymisierung starten“.
                  </MDTypography>
                  <MDTypography variant="h5" fontWeight="bold" mt={3}>
                    2. Anonymisierung & Überprüfung
                  </MDTypography>
                  <MDTypography ml={2} variant="body2" gutterBottom>
                    Der Text wird entweder automatisch anonymisiert oder kopiert. Überprüfen Sie den anonymisierten Text und anonymiseren Sie Informationen falls nötig.
                  </MDTypography>
                  <MDTypography variant="h5" fontWeight="bold" mt={3}>
                    3. Bewertung
                  </MDTypography>
                  <MDTypography ml={2} variant="body2" gutterBottom>
                    Nach Abschluss der Überprüfung bewerten Sie die Qualität der Anonymisierung auf einer Skala von 1 bis 10. Zudem bewerten Sie auf einer Skala von 1 bis 10, inwiefern die Person in automatisch generierten Text reidentifizierbar ist.
                  </MDTypography>
                </MDBox>
                <MDBox mt={4}>
                  <MDButton variant="contained" color="info" onClick={navToStudie}>
                    Los geht's
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </ProtectedContent>
    </DashboardLayout>
  );
}

export default Introduction;
