/**
=========================================================
* Material Dashboard 3 PRO React - v2.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useRef } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';

// Material Dashboard 3 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 3 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";

// Own Components
import ProtectedContent from "../components/protectContent";

// Styles
import { AnimatedGridContainer, AnimatedGridItem } from "./styles/AnimatedGrid";

// Services
import KeycloakService from "services/keycloak";
import BackendService from "services/backend";

// Context
import { useAuthContext } from "contextAuth";
import { useStudieContext } from "contextStudie";

function Study() {
  const [loading, setLoading] = useState(false);
  const [activeSentenceIndex, setActiveSentenceIndex] = useState(null);
  const [toggleAnonymizedTextView, setToggleAnonymizedTextView] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [automaticAnonymizationFinished, setAutomaticAnonymizationFinished] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editedText, setEditedText] = useState("");
  const [toastError, setToastError] = useState(false);
  const [aiToggle, setAiToggle] = useState(false);
  const [toastContent, setToastContent] = useState({ title: "Textfeld leer", description: "Textfeld hat keinen Inhalt" });
  const [timer, setTimer] = useState(0);
  const [studyData, setStudyData] = useState(
    {
      originalText: "",
      autoAnonymizedText:"",
      tokenizedAnonymizedText:[],
      anonymizedText: "",
      backendData: {},
      ranking: {
        autoAnonym: "",
        reident: "",
      },
      withAi: false,
      timeManualAnonym: "", 
      userinfo: {},
    }
  )
  const inputRef = useRef(null);
  const sentenceRefs = useRef([]);

  const backendService = new BackendService();

  const { checkRefreshUser, userInfo } = useAuthContext();
  const { setStudieData } = useStudieContext();

  const onPageLoad = async () => {
    const logInDataFound = await checkRefreshUser();
  }
  useEffect(() => {
    onPageLoad();
  }, []);

  useEffect(() => {
    let interval;
    if (hasStarted) {
      interval = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);
    } else {
      setTimer(0);
    }
    return () => clearInterval(interval);
  }, [hasStarted]);

  useEffect(() => {
    if (
      activeSentenceIndex !== null &&
      sentenceRefs.current[activeSentenceIndex]
    ) {
      sentenceRefs.current[activeSentenceIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [activeSentenceIndex]);

  const openToastError = (title, description) => {
    setToastContent({ title: title, description: description });
    setToastError(true);
  };
  const closeToastError = () => setToastError(false);

  const startAnonymisierung = async () => {
    if (studyData.originalText !== "") {      
      // Start Timer
      setLoading(true);
      setAutomaticAnonymizationFinished(false);
      // Choose 50 / 50 anonym with or without AI
      // const withAI = Math.random() < 0.5;

      if (aiToggle) {
        const responseBackend = await backendService.anonymize({ text: studyData.originalText });
        setStudyData((prev) => ({
          ...prev,
          autoAnonymizedText: responseBackend.anonymizedText,
          tokenizedAnonymizedText: responseBackend.anonymizedTextSentenceTokenized,
          anonymizedText: responseBackend.anonymizedText,
          backendData: responseBackend,
          withAi: true,
          userinfo: userInfo,
        }));
        setAutomaticAnonymizationFinished(true);
        setHasStarted(true);
      } else {
        // Add delay on manual mode
        setTimeout(async () => {
          setStudyData((prev) => ({
            ...prev,
            autoAnonymizedText: prev.originalText,
            anonymizedText: prev.originalText,
            withAi: false,
            userinfo: userInfo,
          }));
          setAutomaticAnonymizationFinished(true);
          setHasStarted(true);
        }, 500);
      }
    } else {
      openToastError("Orginaltext leer", "Orginaltext darf nicht leer sein");
    }
  };
  
  const finishCase = async () => {
    const data = { 
      ...studyData,
      timeManualAnonym: formatTime(timer)
    }
    
    // Save data in context
    setStudieData(data);

    // Jump to last screen
    window.location.href = window.location.origin + "/#/review";

    // Reset Studie
    setHasStarted(false);
    setLoading(false);
    setAutomaticAnonymizationFinished(false);
    setStudyData({
      originalText: "",
      autoAnonymizedText:"",
      anonymizedText: "",
      metadata: [],
      ranking: {
        autoAnonym: "",
        reident: "",
      },
      withAi: false,
      time: {
        autAnonymFinished: "",
        end: "",
      },
      userinfo: {},
    });
  };

  // Format time (MM:SS)
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  const handleCursorChange = () => {
    const cursorPos = inputRef.current?.selectionStart || 0;
    let runningLength = 0;

    for (let i = 0; i < studyData.backendData.originalTextSentenceTokenized.length; i++) {
      runningLength += studyData.tokenizedAnonymizedText[i].length + 1; // +1 for space
      if (cursorPos < runningLength) {
        setActiveSentenceIndex(i);
        return;
      }
    }

    setActiveSentenceIndex(studyData.backendData.originalTextSentenceTokenized.length - 1);
  };

  const handleTextChange = (newValue) => {
    // Get current sentence index using the same logic from cursor tracking
    const index = activeSentenceIndex;
  
    if (index === null || index === undefined) return;
  
    // Find the position of each sentence in the current anonymized text
    const oldFullText = studyData.anonymizedText;
    const oldSentences = studyData.tokenizedAnonymizedText;

    const lenghtDiff = newValue.length - oldFullText.length;
  
    // Compute how much of the text comes before the current sentence
    let startPos = 0;
    for (let i = 0; i < index; i++) {
      startPos += oldSentences[i].length + 1; // +1 for space
    }
  
    // Calculate where the current sentence ends
    const endPos = startPos + oldSentences[index].length + lenghtDiff;

    // Replace that part in the newValue with a substring
    const newSentence = newValue.slice(startPos, endPos).trim();
  
    // Update the sentence array
    const updatedSentences = [...oldSentences];
    updatedSentences[index] = newSentence;
  
    // Update both anonymizedText and tokenizedAnonymizedText
    setStudyData({
      ...studyData,
      anonymizedText: newValue,
      tokenizedAnonymizedText: updatedSentences,
    });
  };

  const handleSaveEdit = (index) => {
    // Create a shallow copy of the sentences
    const updatedSentences = [...studyData.tokenizedAnonymizedText];

    // Update the specific sentence
    updatedSentences[index] = editedText;

    // Join all sentences into one anonymized text
    const updatedAnonymizedText = updatedSentences.join(" ");

    // Update the state
    setStudyData({
      ...studyData,
      tokenizedAnonymizedText: updatedSentences,
      anonymizedText: updatedAnonymizedText,
    });

    // Reset editing state
    setEditIndex(null);
    setEditedText("");
  };

  return (
    <DashboardLayout>
      <ProtectedContent>
        <DashboardNavbar />
        {toastError &&
          <MDSnackbar
            color="error"
            icon="warning"
            title={toastContent.title}
            content={toastContent.description}
            dateTime=""
            open={toastError}
            onClose={closeToastError}
            close={closeToastError}
            bgWhite
          />
        }
        <MDBox pb={3}>
          <AnimatedGridContainer
            container
            spacing={3}
            justify={loading ? "start" : "center"}
          >
            <Grid item xs={6} md={6} lg={6}>
              <Card>
                <MDBox p={2} display="flex" flexDirection="column" gap={2}>
                  <MDBox display="flex" flexDirection="row" justifyContent="space-between">
                    <MDTypography variant="h3">Orginaltext</MDTypography>
                    {!hasStarted && <MDBox display="flex" flexDirection="row" alignItems="center">
                      <MDTypography variant="body2">AI:</MDTypography>
                      <Switch value={aiToggle} onChange={() => setAiToggle(prev => !prev)} disabled={loading} />
                    </MDBox>}
                  </MDBox>
                  {(automaticAnonymizationFinished && aiToggle) ? (
                    <MDBox display="flex" flexDirection="column" gap={1} style={{ maxHeight:"40rem", overflowY:"auto" }}>
                      {studyData.backendData.originalTextSentenceTokenized.map((sentence, sen_index) => (
                        <MDBox
                          key={sen_index}
                          ref={(el) => (sentenceRefs.current[sen_index] = el)}
                          sx={{
                            backgroundColor: sen_index === activeSentenceIndex ? '#e3f2fd' : 'transparent',
                            borderLeft: sen_index === activeSentenceIndex ? '4px solid #2196f3' : '4px solid transparent',
                            paddingLeft: 1,
                            borderRadius: 1,
                            marginBottom: 0.5,
                          }}
                        >
                          <MDTypography>
                            {sentence}
                          </MDTypography>
                        </MDBox>
                      ))}
                    </MDBox>
                  ) : ( 
                    <MDInput
                      label={loading ? "Originaltext" : "Hier den Orginaltext einfügen"}
                      value={studyData.originalText}
                      onChange={(e) => setStudyData((prev) => ({ ...prev, ["originalText"]: e.target.value }))}
                      multiline
                      rows={13}
                      disabled={loading}
                      style={{
                        width: "100%"
                      }} />
                  )}
                  <MDBox display="flex" justifyContent="end">
                    {!loading && (
                      <MDButton color="info" onClick={startAnonymisierung}>
                        Anonymisieren
                      </MDButton>
                    )}
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>

            {loading && (
              <AnimatedGridItem
                item
                xs={6} md={6} lg={6}
                justify={loading ? "start" : "center"}
              >
                <Card>
                  <MDBox p={2} display="flex" flexDirection="column" gap={2}>
                    <MDBox display="flex" flexDirection="row" justifyContent="space-between" gap={2} >
                      <MDTypography variant="h3">Anonymisierung</MDTypography>
                      <MDBox display="flex" flexDirection="row" alignItems="center" gap={3}>
                        {(hasStarted && aiToggle) && 
                          <MDBox display="flex" flexDirection="row" alignItems="center">
                            <MDTypography variant="body2">Volltext Ansicht:</MDTypography>
                            <Switch value={toggleAnonymizedTextView} onChange={() => setToggleAnonymizedTextView(prev => !prev)} disabled={!aiToggle} />
                          </MDBox>
                        }
                        {hasStarted &&
                          <MDTypography mr={1} variant="body2" fontWeight="bold">
                            ⏱ {formatTime(timer)}
                          </MDTypography>
                        }
                      </MDBox>
                    </MDBox>
                    {automaticAnonymizationFinished ? (
                      (!toggleAnonymizedTextView && aiToggle) ? (
                        <MDBox display="flex" flexDirection="column" gap={1} style={{ maxHeight:"40rem", overflowY:"auto" }}>
                          {studyData.tokenizedAnonymizedText.map((sentence, sen_index) => (
                            <MDBox
                              key={sen_index}
                              ref={(el) => (sentenceRefs.current[sen_index] = el)}
                              onClick={() => setActiveSentenceIndex(sen_index)}
                              sx={{
                                backgroundColor: sen_index === activeSentenceIndex ? '#e3f2fd' : 'transparent',
                                borderLeft: sen_index === activeSentenceIndex ? '4px solid #2196f3' : '4px solid transparent',
                                paddingLeft: 1,
                                borderRadius: 1,
                                marginBottom: 0.5,
                              }}
                            >
                              {editIndex === sen_index ? (
                                <MDInput
                                  autoFocus
                                  fullWidth
                                  multiline
                                  value={editedText}
                                  onChange={(e) => setEditedText(e.target.value)}
                                  onBlur={() => handleSaveEdit(sen_index)}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") handleSaveEdit(sen_index);
                                  }}
                                  InputProps={{
                                    sx: {
                                      fontSize: '1.1rem',
                                    },
                                  }}
                                />
                              ) : (
                                <MDTypography onDoubleClick={() => {
                                  setEditIndex(sen_index);
                                  setEditedText(sentence);
                                }}>
                                  {sentence}
                                </MDTypography>
                              )}
                            </MDBox>
                          ))}
                        </MDBox>
                      ) : (
                        <MDInput
                          inputRef={inputRef}
                          label="Enter your original text here..."
                          value={studyData.anonymizedText}
                          onChange={
                            aiToggle ?
                              (e) => handleTextChange(e.target.value)
                            :
                              (e) => setStudyData((prev) => ({ ...prev, ["anonymizedText"]: e.target.value }))
                          }
                          onClick={aiToggle ? handleCursorChange : undefined}
                          onKeyUp={aiToggle ? handleCursorChange : undefined}
                          multiline
                          rows={12}
                          style={{ width: '100%' }}
                          InputProps={{
                            sx: {
                              fontSize: '1.2rem',
                            },
                          }}
                        />
                      )
                    ) : (
                      <MDBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        gap={3}
                        style={{ height:"22rem" }}
                      >
                        <CircularProgress color="info" />
                        <MDTypography>Automatische Automatisierung läuft...</MDTypography>
                      </MDBox>
                    )}
                    
                    {automaticAnonymizationFinished &&
                      <MDBox display="flex" justifyContent="end">
                        <MDButton color="info" onClick={finishCase}>
                          Ergebnis speichern
                        </MDButton>
                      </MDBox>
                    }
                  </MDBox>
                </Card>
              </AnimatedGridItem>
            )}
          </AnimatedGridContainer>
        </MDBox>
      </ProtectedContent>
    </DashboardLayout>
  );
}

export default Study;
