import React, { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Own components
import ShowCard from "./components/deidProzess/components/ShowCard";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Handler
import ExpressHandler from "handler/expressHandler";
import ConfigurationCard from "./components/deidProzess/components/ConfigurationCard";

function Lc_Fallauswertung() {
  const expressHandler = new ExpressHandler();

  // Variables for deidentification
  const [deidentifyTitle, setDeidentifyTitle] = useState("");
  const [deidentifyFallnummer, setDeidentifyFallnummer] = useState("");
  const [orginalText, setOrginalText] = useState("");
  const [expertText, setExpertText] = useState("");
  const [processedText, setProcessedText] = useState("");
  const [rbText, setRbText] = useState("");
  const [llmText, setLlmText] = useState("");
  const [data, setData] = useState([]);
  const [dataImportedData, setDataImportedData] = useState([]);
  const [importedDataNumber, setImportedDataNumber] = useState(0);
  const [metaData, setMetaData] = useState({createdAt:"", processedAt:"", updatedAt:""})

  const onPageLoad = async () => {
    const response_productionData = await expressHandler.getProductionData();
    const response_importedData = await expressHandler.getImportData();

    const impData = [];
    response_productionData.forEach(element => {
      const temp_data = response_importedData.filter((item) => item._id === element.importedDataId);
      impData.push(temp_data[0]);
    });

    setImportedDataNumber(0);
    setDataImportedData(impData);
    setDeidentifyTitle(impData[0].titel);
    setDeidentifyFallnummer(impData[0].fallnummer);
    setExpertText(impData[0].bearbeitetFallbeschreibung);
    setData(response_productionData);
    setOrginalText(impData[0].originalFallbeschreibung);
    setProcessedText(response_productionData[0].postprocessedTxt[0]);

    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'UTC'
    };

    const date = new Date(impData[0].createdAt);
    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date).replace(',', '');
    const date_02 = new Date(response_productionData[0].updatedAt);
    const formattedDate_02 = new Intl.DateTimeFormat('en-GB', options).format(date_02).replace(',', '');
    setMetaData({createdAt:formattedDate, processedAt:formattedDate_02, updatedAt:formattedDate_02});

  };
  useEffect(() => {
    onPageLoad();
  }, []);

  const chooseData = (num) => {
    setImportedDataNumber(num);
    setDeidentifyTitle(dataImportedData[num].titel);
    setDeidentifyFallnummer(dataImportedData[num].fallnummer);
    setOrginalText(dataImportedData[num].originalFallbeschreibung);
    setExpertText(dataImportedData[num].bearbeitetFallbeschreibung);
    setProcessedText(data[num].postprocessedTxt[0]);

    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
      timeZone: 'UTC'
    };

    const date = new Date(dataImportedData[num].createdAt);
    const formattedDate = new Intl.DateTimeFormat('en-GB', options).format(date).replace(',', '');
    const date_02 = new Date(data[num].updatedAt);
    const formattedDate_02 = new Intl.DateTimeFormat('en-GB', options).format(date_02).replace(',', '');
    setMetaData({createdAt:formattedDate, processedAt:formattedDate_02, updatedAt:formattedDate_02});
  }

  const nextData = () => {
    if (importedDataNumber === data.length-1) {
      chooseData(0);
    } else {
      chooseData(importedDataNumber+1);
    }
  }
  const prevData = () => {
    if (importedDataNumber === 0) {
      chooseData(data.length-1);
    } else {
      chooseData(importedDataNumber-1);
    }
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container spacing={5} rowSpacing={5}>
          <Grid item xs={12}>
              <MDBox display="flex" justifyContent="space-between">
                <MDBox display="flex" gap={1}>
                  <MDButton variant="gradient" color="dark" onClick={() => prevData()} style={{ fontSize:"20px" }}>
                    &lt;
                  </MDButton>
                  <MDButton variant="gradient" color="dark" onClick={() => nextData()} style={{ fontSize:"20px" }}>
                    &gt;
                  </MDButton>
                  <MDBox display="flex" flexDirection="column" ml={2}>
                    <MDTypography variant="h6">{deidentifyTitle}</MDTypography>
                    <MDTypography variant="subtitle2">Fallnummer: {deidentifyFallnummer}</MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox mt={5}>
                <MDTypography variant="h5" style={{ marginBottom:"8px" }}>Fallbeschreibung</MDTypography>
                <Grid container spacing={5} rowSpacing={5}>
                  <ShowCard 
                    text={orginalText}
                    header="Originaler Text"
                  />
                  <ConfigurationCard 
                    header="Konfiguration"
                    metaData={metaData}
                  />
                  <ShowCard 
                    text={expertText}
                    header="Experten Ergebnis"
                  />
                  <ShowCard 
                    text={processedText}
                    header="Nachbearbeitetes Ergebnis"
                  />
                </Grid>
              </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Lc_Fallauswertung;
