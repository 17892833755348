import MDBox from "components/MDBox";

import UploadingPopupRoot from "./UploadingPopupRoot";
import MDTypography from "components/MDTypography";

import { useMaterialUIController } from "context";

function UploadingPopup() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    openConfigurator,
    fixedNavbar,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  return (
    <UploadingPopupRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        p={3}
      >
        <MDTypography>Datei wird hochgeladen...</MDTypography>
      </MDBox>
    </UploadingPopupRoot>
  );
}

export default UploadingPopup;