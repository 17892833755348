/**
=========================================================
* Material Dashboard 3 PRO React - v2.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tooltip from '@mui/material/Tooltip';

// Material Dashboard 3 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 3 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";

// Own Components
import ProtectedContent from "../components/protectContent";

// Context
import { useAuthContext } from "contextAuth";
import { useStudieContext } from "contextStudie";

// Data
import { tabValues } from "./data/tabValues";

// Services
import MongoService from "services/mongo";


function Review() {
  const [toastError, setToastError] = useState(false);
  const [toastContent, setToastContent] = useState({ title: "Textfeld leer", description: "Textfeld hat keinen Inhalt" });
  const [tabBalues, setTabValues] = useState([4, 4]);
  const [studyData, setStudyData] = useState(
    {
      originalText: "",
      autoAnonymizedText:"",
      anonymizedText: "",
      metadata: [],
      ranking: {
        autoAnonym: "",
        reident: "",
      },
      withAi: false,
      time: {
        autAnonymFinished: "",
        end: "",
      },
      userinfo: {},
    }
  );
  
  const mongoService = new MongoService();
  const { checkRefreshUser } = useAuthContext();
  const { studieData, setStudieData } = useStudieContext();

  const onPageLoad = async () => {
    const logInDataFound = await checkRefreshUser();
    console.log(studieData);
    
    if (studieData != null) {
      setStudyData(studieData);
    }
    
  }
  useEffect(() => {
    onPageLoad();
  }, []);

  const openToastError = (title, description) => {
    setToastContent({ title: title, description: description });
    setToastError(true);
  };
  const closeToastError = () => setToastError(false);

  const handleSetTabValue = (event, newValue, index) => {
    setTabValues((prev) => prev.map((value, i) => (i === index ? newValue : value)));
  };

  const saveAndEndStudy = async() => {
    console.log(studyData);
    let data = { ...studyData }

    // Add Ranking to studieData
    data.ranking.autoAnonym = tabBalues[0];
    data.ranking.reident = tabBalues[1];
    console.log(data);
    
    // Save studieData in MongoDB
    const respSave = await mongoService.createCase(data);
    console.log(respSave);
    
    // Reset Context studieData
    setStudieData({
      originalText: "",
      autoAnonymizedText:"",
      anonymizedText: "",
      metadata: [],
      ranking: {
        autoAnonym: "",
        reident: "",
      },
      withAi: false,
      time: {
        autAnonymFinished: "",
        end: "",
      },
      userinfo: {},
    });
    // Navigate to Einfuehrung
    window.location.href = window.location.origin + "/#/einfuehrung";
  };

  

  return (
    <DashboardLayout>
      <ProtectedContent>
        <DashboardNavbar />
        {toastError &&
          <MDSnackbar
            color="error"
            icon="warning"
            title={toastContent.title}
            content={toastContent.description}
            dateTime=""
            open={toastError}
            onClose={closeToastError}
            close={closeToastError}
            bgWhite
          />
        }
        <MDBox pb={3}>
          <Grid container spacing={3}>
            <Grid item xs={4} md={4} lg={4}>
              <Card>
                <MDBox p={2} display="flex" flexDirection="column" gap={2}>
                  <MDTypography>Originaltext</MDTypography>
                  {/* <MDInput
                    label=""
                    value={studyData.originalText}
                    multiline
                    rows={13}
                    disabled={true}
                    style={{
                      width: "100%"
                    }} /> */}
                    <MDBox px={2} py={1} borderRadius="lg" style={{ border: "1px solid grey", overflow: "auto", maxHeight:"500px", minHeight:"300px", backgroundColor:"#A9A9A9" }}>
                      <MDTypography variant="body2">
                        {studyData.originalText}
                      </MDTypography>
                    </MDBox>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <Card>
                <MDBox p={2} display="flex" flexDirection="column" gap={2}>
                  <MDTypography>Automatisch generierter Text</MDTypography>
                  {/* <MDInput
                    label=""
                    value={studyData.autoAnonymizedText}
                    multiline
                    rows={13}
                    disabled={true}
                    style={{
                      width: "100%"
                    }} /> */}
                    <MDBox px={2} py={1} borderRadius="lg" style={{ border: "1px solid grey", overflow: "auto", maxHeight:"500px", minHeight:"300px", backgroundColor:"#E0E0E0" }}>
                      <MDTypography variant="body2">
                        {studyData.autoAnonymizedText}
                      </MDTypography>
                    </MDBox>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={4} md={4} lg={4}>
              <Card>
                <MDBox p={2} display="flex" flexDirection="column" gap={2}>
                  <MDTypography>Endergebnis</MDTypography>
                  {/* <MDInput
                    label=""
                    value={studyData.anonymizedText}
                    multiline
                    rows={13}
                    disabled={true}
                    style={{
                      width: "100%"
                    }}
                    InputProps={{
                      style: {
                        overflow: "auto", // Enables scrolling
                      },
                    }}  
                  /> */}
                  <MDBox px={2} py={1} borderRadius="lg" style={{ border: "1px solid grey", overflow: "auto", maxHeight:"500px", minHeight:"300px", backgroundColor:"#A9A9A9" }}>
                    <MDTypography variant="body2">
                      {studyData.anonymizedText}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
          <Grid item mt={3} xs={12} md={12} lg={12}>
            <Card>
              {studyData.withAi && (
                <Grid container spacing={3}>

                  <Grid item xs={4} md={4} lg={4}>
                    <MDBox p={2} display="flex" >
                      <MDTypography>Hat die automatisierte Anonymisierung (mittlere Text) qualitätsweise Ihre Erwartungen getroffen?</MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={8} md={8} lg={8}>
                    <MDBox p={2} display="flex" >
                      <AppBar position="static">
                        <Tabs orientation="horizontal" value={tabBalues[0]} onChange={(event, newValue) => handleSetTabValue(event, newValue, 0)}>
                          {tabValues[0].map(tab => (
                            <Tooltip title={tab.label} arrow>
                              <Tab label={tab.number} />
                            </Tooltip>
                          ))}
                        </Tabs>
                      </AppBar>
                    </MDBox>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={3}>
                {studyData.withAi && (
                  <>
                    <Grid item xs={4} md={4} lg={4}>
                      <MDBox p={2} display="flex" >
                        <MDTypography>Ist die Person im automatisch generierten Text re-identifizierbar?</MDTypography>
                      </MDBox>
                    </Grid>
                    <Grid item xs={8} md={8} lg={8}>
                      <MDBox p={2} display="flex" >
                        <AppBar position="static">
                          <Tabs orientation="horizontal" value={tabBalues[1]} onChange={(event, newValue) => handleSetTabValue(event, newValue, 1)}>
                            {tabValues[1].map(tab => (
                              <Tooltip title={tab.label} arrow>
                                <Tab label={tab.number} />
                              </Tooltip>
                            ))}
                          </Tabs>
                        </AppBar>
                      </MDBox>
                    </Grid>
                  </>
                )}
                {!studyData.withAi && (
                  <Grid item xs={12} md={12} lg={12}>
                    <MDBox p={2} display="flex" flexDirection="column" >
                      <MDTypography>Studie wurde ohne KI durchgeführt, daher ist die Bewertung nicht nötig.</MDTypography>
                      <MDTypography>Bitte drücken sie "Bewertung Speichern", um den Vorgang abzuschließen.</MDTypography>
                    </MDBox>
                  </Grid>
                )}
                <Grid item xs={12} md={12} lg={12}>
                  <MDBox p={2} display="flex" justifyContent="end">
                    <MDButton color="info" onClick={saveAndEndStudy}>
                      Bewertung speichern
                    </MDButton>
                  </MDBox>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </MDBox>
      </ProtectedContent>
    </DashboardLayout>
  );
}

export default Review;
