import React, { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";

function HistoryCard({
  header,
  history,
  clickOnHistCard,
}) {
  const StyledMDBox = styled(MDBox)({
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between",
    minWidth:"250px",
    border: "1px solid #dee2e6",
    borderRadius: "8px",
    padding: 5,
    cursor: "pointer",
    '&:hover': {
      backgroundColor: '#F0F0F0',
    },
  });
  return (
    <Grid item xs={12} md={6} lg={12}>
      <MDBox>
        <Card>
          <MDBox
            display="flex"
            flexDirection="column"
            py={1}
            px={2}
            maxHeight="150px"
            style={{ minHeight: "150px" }}
          >
            <MDBox display="flex" justifyContent="space-between">
              <MDTypography variant="h5">{header}</MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="row"
              borderRadius="lg"
              gap={1}
              width="100%"
              style={{
                marginBottom:5,
                marginTop:5,
                overflow: "auto",
                overflowY: "hidden",
              }}
            >
              {history.map((item, index) => {
                const date = new Date(item.createdAt);
                const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;

                return (
                  <StyledMDBox
                    key={`histcard-${index}`}
                    width="250px"
                    onClick={() => clickOnHistCard(index)}
                  >
                    <MDTypography variant="Overline">
                      {`${item.input.slice(0, 20)} ...`}
                    </MDTypography>
                    <MDTypography variant="Overline">
                      {formattedDate}
                    </MDTypography>
                  </StyledMDBox>
                );
              })}
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
  </Grid>
    
  );
}

// Setting default values
HistoryCard.defaultProps = {
  text: "",
  header: "",
};

// Typechecking props 
HistoryCard.propTypes = {
  text: PropTypes.string,
  header: PropTypes.string,
};

export default HistoryCard;
