// @mui material components
import Grid from "@mui/material/Grid";
import { styled, keyframes } from "@mui/material/styles";

const fadeUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeDown = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100px);
  }
`;

export const AnimatedGridContainer = styled(Grid)(({ justify }) => ({
  display: "flex",
  position: "relative",
  transition: "left 0.5s ease",
  left: justify === "start" ? "0%" : "25%",
  justifyContent: "start",
}));

export const AnimatedGridItem = styled(Grid)(({ justify }) => ({
  animation: `${justify === "start" ? fadeUp : fadeDown} 0.5s ease forwards`,
}));