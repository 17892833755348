import { getCases, getUniqueCase, createCase, deleteCase } from "./functions/cases";

export default class MongoService {
  
  async getCases() {
    return await getCases();
  }

  async getUniqueCase(caseId) {
    return await getUniqueCase(caseId);
  }

  async createCase(body) {
    return await createCase(body);
  }

  async deleteCase(caseId) {
    return await deleteCase(caseId);
  }
}