export default function getJsonBodyImportData(newData, userId, department) {
  const bodyList = [];
  const defaultBody = {
    verantwortlicherVorname: "",
    verantwortlicherName: "",
    verantwortlicherEmail: "",
    fallnummer: "",
    systemstatus: "",
    bearbeitungsstatus: "",
    titel: "",
    eingangsdatum: "",
    ersteAnonymisierung: "",
    zweiteAnonymisierung: "",
    veroeffentlichungImPortal: "",
    eingangUeberEbuero: "",
    vorgangsart: "",
    originalFallbeschreibung: "",
    bearbeitetFallbeschreibung: "",
    originalNichtGutVerlaufen: "",
    bearbeitetNichtGutVerlaufen: "",
    originalBesondersGutPositiv: "",
    bearbeitetBesondersGutPositiv: "",
    originalBeitragendeFaktoren: "",
    bearbeitetBeitragendeFaktoren: "",
    originalAusFallLernen: "",
    bearbeitetAusFallLernen: "",
    originalWeitereGedanken: "",
    bearbeitetWeitereGedanken: "",
    kategoriebaumBeitragendeFaktoren: "",
    kategorieBaumFormularKategorien: "",
    weitereSymptomeBeschwerden: "",
    haeufigkeitCovid: "",
    kategoriebaumLongCovid: "",
    kategoriebaumVersFormulare: "",
    kategoriebaumEingangsart: "",
    analyseGedankenUrsache: "",
    analysePraeventionsmassnahmen: "",
    analyseBemerkung: "",
    analyseSonstigeInfos: "",
    analyseExternerLink: "",
    kategoriebaumRPZ: "",
    deidentifiziert: false,
    userId: userId,
    department: department
  };
  newData.forEach(item => {
    let tempBody = { ...defaultBody };
    if(item["Verantwortlicher Vorname"]) {
      tempBody.verantwortlicherVorname=item["Verantwortlicher Vorname"]
    };
    if(item["Verantwortlicher Name"]) {
      tempBody.verantwortlicherName=item["Verantwortlicher Name"]
    };
    if(item["Verantwortlicher E-Mail"]) {
      tempBody.verantwortlicherEmail=item["Verantwortlicher E-Mail"]
    };
    if(item["Fallnummer"]) {
      tempBody.fallnummer=item["Fallnummer"]
    };
    if(item["Systemstatus"]) {
      tempBody.systemstatus=item["Systemstatus"]
    };
    if(item["Bearbeitungsstatus"]) {
      tempBody.bearbeitungsstatus=item["Bearbeitungsstatus"]
    };
    if(item["Titel"]) {
      tempBody.titel=item["Titel"]
    };
    if(item["Eingangsdatum"]) {
      tempBody.eingangsdatum=item["Eingangsdatum"]
    };
    if(item["1. Anonymisierung"]) {
      tempBody.ersteAnonymisierung=item["1. Anonymisierung"]
    };
    if(item["2. Anonymisierung"]) {
      tempBody.zweiteAnonymisierung=item["2. Anonymisierung"]
    };
    if(item["Veröffentlichung im Portal"]) {
      tempBody.veroeffentlichungImPortal=item["Veröffentlichung im Portal"]
    };
    if(item["Eingang über eBüro"]) {
      tempBody.eingangUeberEbuero=item["Eingang über eBüro"]
    };
    if(item["Vorgangsart"]) {
      tempBody.vorgangsart=item["Vorgangsart"]
    };
    if(item["Originaltext: Fallbeschreibung"]) {
      tempBody.originalFallbeschreibung=item["Originaltext: Fallbeschreibung"]
    };
    if(item["Bearbeitet: Fallbeschreibung"]) {
      tempBody.bearbeitetFallbeschreibung=item["Bearbeitet: Fallbeschreibung"]
    };
    if(item["Originaltext: Was ist in Ihrem Fall nicht gut verlaufen?"]) {
      tempBody.originalNichtGutVerlaufen=item["Originaltext: Was ist in Ihrem Fall nicht gut verlaufen?"]
    };
    if(item["Bearbeitet: Was ist in Ihrem Fall nicht gut verlaufen?"]) {
      tempBody.bearbeitetNichtGutVerlaufen=item["Bearbeitet: Was ist in Ihrem Fall nicht gut verlaufen?"]
    };
    if(item["Originaltext: Was war in Ihrem Fall ggf. besonders gut/positiv?"]) {
      tempBody.originalBesondersGutPositiv=item["Originaltext: Was war in Ihrem Fall ggf. besonders gut/positiv?"]
    };
    if(item["Bearbeitet: Was war in Ihrem Fall ggf. besonders gut/positiv?"]) {
      tempBody.bearbeitetBesondersGutPositiv=item["Bearbeitet: Was war in Ihrem Fall ggf. besonders gut/positiv?"]
    };
    if(item["Originaltext: Was waren beitragende Faktoren?"]) {
      tempBody.originalBeitragendeFaktoren=item["Originaltext: Was waren beitragende Faktoren?"]
    };
    if(item["Bearbeitet: Was waren beitragende Faktoren?"]) {
      tempBody.bearbeitetBeitragendeFaktoren=item["Bearbeitet: Was waren beitragende Faktoren?"]
    };
    if(item["Originaltext: Was könnte man aus Ihrem Fall lernen?"]) {
      tempBody.originalAusFallLernen=item["Originaltext: Was könnte man aus Ihrem Fall lernen?"]
    };
    if(item["Bearbeitet: Was könnte man aus Ihrem Fall lernen?"]) {
      tempBody.bearbeitetAusFallLernen=item["Bearbeitet: Was könnte man aus Ihrem Fall lernen?"]
    };
    if(item["Originaltext: Haben Sie noch weitere Gedanken zu Ihrem Fall?"]) {
      tempBody.originalWeitereGedanken=item["Originaltext: Haben Sie noch weitere Gedanken zu Ihrem Fall?"]
    };
    if(item["Bearbeitet: Haben Sie noch weitere Gedanken zu Ihrem Fall?"]) {
      tempBody.bearbeitetWeitereGedanken=item["Bearbeitet: Haben Sie noch weitere Gedanken zu Ihrem Fall?"]
    };
    if(item["Kategoriebaum Beitragende Faktoren"]) {
      tempBody.kategoriebaumBeitragendeFaktoren=item["Kategoriebaum Beitragende Faktoren"]
    };
    if(item["Kategoriebaum Formularkategorien"]) {
      tempBody.kategoriebaumFormularKategorien=item["Kategoriebaum Formularkategorien"]
    };
    if(item["Weitere Symptome/Beschwerden"]) {
      tempBody.weitereSymptomeBeschwerden=item["Weitere Symptome/Beschwerden"]
    };
    if(item["Wie oft hatten Sie / die betroffene Person Covid?"]) {
      tempBody.haeufigkeitCovid=item["Wie oft hatten Sie / die betroffene Person Covid?"]
    };
    if(item["Kategoriebaum LONG COVID"]) {
      tempBody.kategoriebaumLongCovid=item["Kategoriebaum LONG COVID"]
    };
    if(item["Kategoriebaum Vers. Formular"]) {
      tempBody.kategoriebaumVersFormulare=item["Kategoriebaum Vers. Formular"]
    };
    if(item["Kategoriebaum Eingangsart"]) {
      tempBody.kategoriebaumEingangsart=item["Kategoriebaum Eingangsart"]
    };
    if(item["Analyse: Gedanken zur Analyse/Ursachen"]) {
      tempBody.analyseGedankenUrsache=item["Analyse: Gedanken zur Analyse/Ursachen"]
    };
    if(item["Analyse: Präventionsmaßnahmen"]) {
      tempBody.analysePraeventionsmassnahmen=item["Analyse: Präventionsmaßnahmen"]
    };
    if(item["Analyse: Bemerkungen"]) {
      tempBody.analyseBemerkung=item["Analyse: Bemerkungen"]
    };
    if(item["Analyse: sonstige Informationen (intern)"]) {
      tempBody.analyseSonstigeInfos=item["Analyse: sonstige Informationen (intern)"]
    };
    if(item["Analyse: Externer Link"]) {
      tempBody.analyseExternerLink=item["Analyse: Externer Link"]
    };
    if(item["Kategoriebaum RPZ"]) {
      tempBody.kategoriebaumRPZ=item["Kategoriebaum RPZ"]
    };
    bodyList.push(tempBody);
  })

  return bodyList;
}