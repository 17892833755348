import React from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import { useAuthContext } from "contextAuth";

const ProtectedContent = ({ children }) => {
  const { tokens } = useAuthContext();
  const isLoggedIn = Boolean(tokens?.accessToken); // Auth check

  return (
    <MDBox
      sx={{
        position: "relative",
        opacity: isLoggedIn ? 1 : 0.5,
        pointerEvents: isLoggedIn ? "auto" : "none",
      }}
    >
      {children}
    </MDBox>
  );
};

ProtectedContent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedContent;
