import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Handler
// import ExpressHandler from "handler/expressHandler";

function TextLabeling({ tokens, setTokens, taggedEntities, setTaggedEntities }) {
  const [originalWords, setOriginalWords] = useState(null);
  const [markedWordList, setMarkedWordList] = useState(() => {
    return Array(tokens.length).fill(false);
  });
  const [labelingStart, setLabelingStart] = useState(null);
  const [labelingEnd, setLabelingEnd] = useState(null);
  const [lastIndexOfMarkedWords, setLastIndexOfMarkedWords] = useState(null);

  const entities = [
    { tag: "Original", color: "transparent" },
    { tag: "<NAME>", color: "#ffd966" },
    { tag: "<ALTER>", color: "#8dc6ff" },
    { tag: "<ORT>", color: "#a0ebaa" },
    { tag: "<DATUM>", color: "#ffc0cb" },
    { tag: "<TELEFON>", color: "#ff8a85" },
    { tag: "<ID>", color: "#6d7f9f" },
    { tag: "<MEDIKAMENT>", color: "#ffcc99" },
    { tag: "<KRANKHEIT>", color: "#b19cd9" },
  ];
  const closeLabelModal = () => {
    // Close the labeling tool
    setLastIndexOfMarkedWords(null);
    setLabelingStart(null);
    // Erase the marker on the label
    if (markedWordList[labelingEnd]) {
      setMarkedWordList(Array(tokens.length).fill(false));
    }
  };
  // Handle labeling process
  const handleLabeling = (label) => {
    const tempEntityList = [...taggedEntities];
    markedWordList.forEach((marked, index) => {
      if (marked) {
        tempEntityList[index] = label;
      }
    });
    setTaggedEntities(tempEntityList);
    closeLabelModal();
  };
  const handleMouseDown = (index) => {
    if (typeof index == "number") {
      setLabelingStart(index);
    }
  };
  const handleMouseUp = (index) => {
    if (typeof index == "number") {
      // Save the original text
      if (originalWords === null) {
        setOriginalWords(tokens);
      }
      // Initialize the entityList with all words set to tag "Original"
      if (taggedEntities === null) {
        setTaggedEntities(Array(tokens.length).fill("Original"));
      }
      // Check if any other labeling process is ON
      if (markedWordList.some((item) => item === true)) {
        closeLabelModal();
        return;
      }
      // Turn labeling process OFF
      if (markedWordList[index]) {
        // Close the labeling tool
        closeLabelModal();
      }
      // Turn labeling process ON
      else {
        // Set the marker on the selected tokens
        const markedWords = [...markedWordList];
        if (labelingStart > index) {
          setLastIndexOfMarkedWords(labelingStart);
          for (let i = index; i <= labelingStart; i++) {
            markedWords[i] = true;
          }
        } else {
          setLastIndexOfMarkedWords(index);
          for (let i = labelingStart; i <= index; i++) {
            markedWords[i] = true;
          }
        }
        setMarkedWordList(markedWords);
        // Save current index
        setLabelingEnd(index);
      }
    }
  };
  return (
    <MDBox display="flex" flexDirection="column" gap={1} pr={6}>
      <MDBox display="flex" flexDirection="row" flexWrap="wrap" gap="2px" >
        {entities.slice(1).map((entity, index) => (
          <MDBox
            key={`tag-${index}`}
            bgColor={entity.color}
            style={{ height: "20px", fontSize: "14px" }}
          >
            {entity.tag}
          </MDBox>
        ))}
      </MDBox>
      <MDBox
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        {tokens.map((word, index) => {
          return (
            <MDBox
              key={`wordBox-${index}`}
              px="1px"
              bgColor={
                markedWordList[index]
                  ? "#dee2e6"
                  : taggedEntities[index] === "<NAME>"
                  ? entities[1].color
                  : taggedEntities[index] === "<ALTER>"
                  ? entities[2].color
                  : taggedEntities[index] === "<ORT>"
                  ? entities[3].color
                  : taggedEntities[index] === "<DATUM>"
                  ? entities[4].color
                  : taggedEntities[index] === "<TELEFON>"
                  ? entities[5].color
                  : taggedEntities[index] === "<ID>"
                  ? entities[6].color
                  : taggedEntities[index] === "<MEDIKAMENT>"
                  ? entities[7].color
                  : taggedEntities[index] === "<KRANKHEIT>"
                  ? entities[8].color
                  : entities[0].color
              }
              position="relative"
              display="flex"
              style={{ cursor: "text" }}
            >
              <MDTypography
                variant="body2"
                onMouseDown={() => handleMouseDown(index)}
                onMouseUp={() => handleMouseUp(index)}
                // color={index === hoverIndex ? "white" : "dark"}
              >
                {word}
              </MDTypography>
              {/* Dropdown of entities */}
              {index === lastIndexOfMarkedWords && (
                <MDBox
                  position="absolute"
                  top="100%"
                  left="-25px"
                  width="160px"
                  height="120px"
                  bgColor="#ced4da"
                  borderRadius="lg"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ zIndex: 2 }}
                >
                  <MDBox
                    width="140px"
                    height="100px"
                    display="flex"
                    flexDirection="column"
                    gap="2px"
                    style={{ zIndex: 2, overflowY: "auto" }}
                  >
                    {entities.map((item) => (
                      <MDTypography
                        variant="body2"
                        onClick={() => handleLabeling(item.tag)}
                        style={{
                          textAlign: "center",
                          backgroundColor: item.color,
                          cursor: "pointer",
                        }}
                      >
                        {item.tag}
                      </MDTypography>
                    ))}
                    {/* <MDTypography
                      variant="body2"
                      onClick={() => handleLabeling(entities[0].tag)}
                      style={{
                        textAlign: "center",
                        backgroundColor: entities[0].color,
                        cursor: "pointer",
                      }}
                    >
                      {entities[0].tag}
                    </MDTypography>
                    <MDTypography
                      variant="body2"
                      onClick={() => handleLabeling(entities[1].tag)}
                      style={{
                        textAlign: "center",
                        backgroundColor: entities[1].color,
                        cursor: "pointer",
                      }}
                    >
                      {entities[1].tag}
                    </MDTypography>
                    <MDTypography
                      variant="body2"
                      onClick={() => handleLabeling(entities[2].tag)}
                      style={{
                        textAlign: "center",
                        backgroundColor: entities[2].color,
                        cursor: "pointer",
                      }}
                    >
                      {entities[2].tag}
                    </MDTypography>
                    <MDTypography
                      variant="body2"
                      onClick={() => handleLabeling(entities[3].tag)}
                      style={{
                        textAlign: "center",
                        backgroundColor: entities[3].color,
                        cursor: "pointer",
                      }}
                    >
                      {entities[3].tag}
                    </MDTypography>
                    <MDTypography
                      variant="body2"
                      onClick={() => handleLabeling(entities[4].tag)}
                      style={{
                        textAlign: "center",
                        backgroundColor: entities[4].color,
                        cursor: "pointer",
                      }}
                    >
                      {entities[4].tag}
                    </MDTypography>
                    <MDTypography
                      variant="body2"
                      onClick={() => handleLabeling(entities[5].tag)}
                      style={{
                        textAlign: "center",
                        backgroundColor: entities[5].color,
                        cursor: "pointer",
                      }}
                    >
                      {entities[5].tag}
                    </MDTypography>
                    <MDTypography
                      variant="body2"
                      onClick={() => handleLabeling(entities[6].tag)}
                      style={{
                        textAlign: "center",
                        backgroundColor: entities[6].color,
                        cursor: "pointer",
                      }}
                    >
                      {entities[6].tag}
                    </MDTypography> */}
                  </MDBox>
                </MDBox>
              )}
            </MDBox>
          );
        })}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of MDInput
TextLabeling.defaultProps = {
  tokens: [],
  setTokens: () => {},
};

// Typechecking props for the MDInput
TextLabeling.propTypes = {
  tokens: PropTypes.array,
  setTokens: PropTypes.func.isRequired,
};

export default TextLabeling;
