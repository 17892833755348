/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState } from "react";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

// Additional components (React Spinner)
import BeatLoader from "react-spinners/BeatLoader";
import MDBox from "components/MDBox";

export default function getDocumentsTableData(docs, impData, loading, setLoading) {
  const rows = [];
  let importedBadge = false;
  const importData = async (docRowNumber) => {
    setLoading(true);
    await impData(docRowNumber);
    setLoading(false);
  }
  for (let i = 0; i < docs.length; i++) {
    rows.push({
      fileName: (
        <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
          {docs[i].name}
        </MDTypography>
      ),
      size: (
        <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
          {Math.round(docs[i].size / 1024)} KB
        </MDTypography>
      ),
      // status: (
      //   <MDBox>
      //     {importedBadge ? (
      //       <MDBadge badgeContent="importiert" size="sm" color="success" container />
      //     ) : (
      //       <MDBadge badgeContent="nicht importiert" size="sm" color="warning" container />
      //     )}
      //   </MDBox>
      // ),
      deid: (
        <MDBox>
          {loading ? (
            <MDBox
              borderRadius="lg"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <BeatLoader
                color="black"
                loading={loading}
                size={10}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </MDBox>
          ) : (
            docs[i].importiert ? (
              <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
                Schon Importiert
              </MDTypography>
            ) : (
              <MDButton variant="gradient" color="dark" onClick={() => importData(i)}>
                Import
              </MDButton>
            )
          )}
        </MDBox>
      ),
    });
  }
  return {
    columns: [
      { Header: "Dokumentname", accessor: "fileName", align: "center" },
      { Header: "Größe", accessor: "size", align: "center" },
      // { Header: "Status", accessor: "status", align: "center" },
      { Header: "", accessor: "deid", align: "center" },
    ],
    rows: rows,
  };
}
