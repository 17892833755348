import React, { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

function ConfigurationCard({
  header,
  metaData
}) {
  return (
    <Grid item xs={12} md={6} lg={6}>
      <MDBox>
        <Card>
          <MDBox
            display="flex"
            flexDirection="column"
            rowGap={1}
            py={1}
            px={2}
            maxHeight="325px"
            style={{ minHeight: "325px" }}
          >
            <MDBox display="flex" justifyContent="space-between">
              <MDTypography variant="h5">{header}</MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              flexDirection="column"
              padding="10px 40px 0px 25px"
              style={{
                marginBottom:10,
                marginTop:10,
                height:1000,
                maxHeight: "100%",
              }}
            >
              <MDBox pr={20} display="flex" flexDirection="row" justifyContent="space-between">
                <MDTypography>Benutztes Modell: </MDTypography>
                <MDTypography>Modell 2</MDTypography>
              </MDBox>
              <MDBox pr={20} display="flex" flexDirection="row" justifyContent="space-between">
                <MDTypography>Fall hochgeladen: </MDTypography>
                <MDTypography>{metaData.createdAt}</MDTypography>
              </MDBox>
              <MDBox pr={20} display="flex" flexDirection="row" justifyContent="space-between">
                <MDTypography>Fall prozessiert: </MDTypography>
                <MDTypography>{metaData.processedAt}</MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </Grid>
  );
}

// Setting default values
ConfigurationCard.defaultProps = {
  text: "",
  header: "",
};

// Typechecking props 
ConfigurationCard.propTypes = {
  text: PropTypes.string,
  header: PropTypes.string,
};

export default ConfigurationCard;
