// Class to connect React with Express
class ExpressHandler {
  // baseURL = "http://81.89.199.178:5003";
  baseURL = "https://xp-mogo.covid-companion.de";


  // ------------------ TEXTINPUT ---------------------
  // Post a new text input
  async createTxt(body) {
    // expects body={txt:String}
    const response = await fetch(this.baseURL + "/api/txtinput", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (!response.ok) {
      console.log("text input could not be saved");
    }
    if (response.ok) {
      console.log("text input successfully saved in mongoDB");
      return json;
    }
  }
  // Delete a text input
  async deleteTxtInput(id) {
    const response = await fetch(this.baseURL + "/api/txtinput/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (response.ok) {
      console.log("text input successfully deleted from mongodb");
      return json;
    }
  }
  // ------------------ RAW_DATA ---------------------
  // Get all Data
  async getMetadataRawData() {
    const response = await fetch(this.baseURL + "/api/raw_data");
    const json = await response.json();
    if (response.ok) {
      return json;
    }
  }
  // Get all Users
  async getRawDataById(id) {
    const response = await fetch(this.baseURL + "/api/raw_data/" + id);
    const json = await response.json();
    if (response.ok) {
      return json;
    }
  }
  // Post a new raw_data
  async createRawData(body) {
    // expects body={txt:String}
    const response = await fetch(this.baseURL + "/api/raw_data", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (!response.ok) {
      console.log("document could not be saved");
    }
    if (response.ok) {
      console.log("document successfully saved in mongoDB");
      return json;
    }
  }

  // Update raw_data
  async updateRawData(body, id) {
    // expects body={txt:String}
    const response = await fetch(this.baseURL + "/api/raw_data/" + id, {
      method: "PATCH",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (!response.ok) {
      console.log("document could not be saved");
    }
    if (response.ok) {
      console.log("document successfully saved in mongoDB");
      return json;
    }
  }
  // ------------------ IMPORT_DATA ---------------------
  // Get all data
  async getImportData() {
    const response = await fetch(this.baseURL + "/api/import_data");
    const json = await response.json();
    if (response.ok) {
      return json;
    }
  }
  // Get all fallnummern
  async getImportDataFallnummern() {
    const response = await fetch(this.baseURL + "/api/import_data/fallnummern");
    console.log(response);
    const json = await response.json();
    if (response.ok) {
      return json;
    }
  }
  // Post a new importedData
  async createImportData(body) {
    // expects body={txt:String}
    const response = await fetch(this.baseURL + "/api/import_data", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (!response.ok) {
      console.log("data import could not be saved");
    }
    if (response.ok) {
      console.log("data import was successfully");
      return json;
    }
  }
  // Patch a importedData
  async updateImportData(id, body) {
    // expects body={txt:String}
    const response = await fetch(this.baseURL + "/api/import_data/" + id, {
      method: "PATCH",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (!response.ok) {
      console.log("data import could not be saved");
    }
    if (response.ok) {
      console.log("data import was successfully");
      return json;
    }
  }

  // ------------------ PRODUCTION_DATA ---------------------
  // Get all productionData
  async getProductionData() {
    const response = await fetch(this.baseURL + "/api/production_data");
    const json = await response.json();
    if (response.ok) {
      return json;
    }
  }
  // Get a single productionData
  async getSingleProductionData(id) {
    const response = await fetch(this.baseURL + "/api/production_data/" + id);
    const json = await response.json();
    if (response.ok) {
      return json;
    }
  }
  // Post a new productionData
  async createProductionData(body) {
    // expects body={txt:String}
    const response = await fetch(this.baseURL + "/api/production_data", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (!response.ok) {
      console.log("productionData could not be saved");
    }
    if (response.ok) {
      console.log("productionData successfully saved in mongoDB");
      return json;
    }
  }

  // ------------------ RESEARCH_DATA ---------------------
  // Get all researchData
  async getResearchData() {
    const response = await fetch(this.baseURL + "/api/researchData");
    const json = await response.json();
    if (response.ok) {
      return json;
    }
  }
  // Get a single researchData
  async getSingleResearchData(id) {
    const response = await fetch(this.baseURL + "/api/researchData/" + id);
    const json = await response.json();
    if (response.ok) {
      return json;
    }
  }
  // Post a new researchData
  async createResearchData(body) {
    // expects body={txt:String}
    const response = await fetch(this.baseURL + "/api/researchData", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (!response.ok) {
      console.log("document could not be saved");
    }
    if (response.ok) {
      console.log("document successfully saved in mongoDB");
      return json;
    }
  }

  // ------------------ FALLAUSWERTUNG ---------------------
  // Get all Data
  async getFallauswertungData() {
    const response = await fetch(this.baseURL + "/api/falluebersicht");
    const json = await response.json();
    if (response.ok) {
      return json;
    }
  }
  // ------------------ FREI TEXTE ---------------------
  // Get all data
  async getFreiTexteHistory() {
    const response = await fetch(this.baseURL + "/api/freiTexte");
    const json = await response.json();
    if (response.ok) {
      return json;
    }
  }
  // Post a new data
  async createFreiTexteHistory(body) {
    const response = await fetch(this.baseURL + "/api/freiTexte", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (!response.ok) {
      console.log("frei text could not be saved");
    }
    if (response.ok) {
      console.log("frei text was successfully saved");
      return json;
    }
  }

  // ------------------ CURRENT STUDY ---------------------
  // Get all data
  async getCurrentStudy(userId) {
    const response = await fetch(this.baseURL + "/api/currentStudy/" + userId);
    const json = await response.json();
    if (response.ok) {
      return json;
    }
  }
  // Post a current study
  async createCurrentStudy(body) {
    const response = await fetch(this.baseURL + "/api/currentStudy", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (!response.ok) {
      console.log("current study could not be saved");
    }
    if (response.ok) {
      console.log("current study was successfully saved");
      return json;
    }
  }
  
  // Patch current study
  async updateCurrentStudy(id, body) {
    // expects body={txt:String}
    const response = await fetch(this.baseURL + "/api/currentStudy/" + id, {
      method: "PATCH",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (!response.ok) {
      console.log("current study could not be saved");
    }
    if (response.ok) {
      console.log("current study was successfully saved");
      return json;
    }
  }

  // Delete a current study
  async deleteCurrentStudy(id) {
    const response = await fetch(this.baseURL + "/api/currentStudy/" + id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (response.ok) {
      console.log("current study successfully deleted from mongodb");
      return json;
    }
  }
}

export default ExpressHandler;
