import { createContext, useContext, useState, useEffect } from "react";
// import CookieBanner from "../components/CookieBanner/CookieBanner";

const CookiesContext = createContext();
export const useCookiesContext = () => useContext(CookiesContext);

export const CookiesContextProvider = ({ children }) => {

  // const [showCookieBanner, setShowCookieBanner] = useState(false);

  // Check for existing UUID cookie on mount
  // useEffect(() => {
  //   const userUuid = getCookie('user_uuid');
  //   if (!userUuid) {
  //     setShowCookieBanner(true);
  //   }
  // }, []);

  const getCookie = (name) => {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split('=');
      if (cookieName && cookieName === name) {        
        return cookieValue;
      }
    }
    return null;
  };

  /**
   * Sets a cookie with the given name and value.
   * @param {string} name - The name of the cookie.
   * @param {string} value - The value of the cookie.
   * @param {number} [millis] - Optional. The number of milliseconds until the cookie expires.
   * Example:
   * const authentificationManager = new AuthentificationManager();
   * authentificationManager.setCookie("GesamtZiel",GesamtZiel,30000000);
   */
  const setCookie = (name, value, millis) => {
    const expirationDate = new Date();
    if (millis) {
      expirationDate.setTime(expirationDate.getTime() + millis);
    }
    const cookieString = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`; // cloud version
    // const cookieString = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/; SameSite=None; Secure`; //localhost version
    document.cookie = cookieString;
  };

  const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };

  return (
    <CookiesContext.Provider
      value={{
        getCookie,
        setCookie,
        deleteCookie,
        // setShowCookieBanner
      }}>
      {/* {showCookieBanner && <CookieBanner />} */}
      {children}
    </CookiesContext.Provider>
  );
};
