import React, { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";

function InputTextCard({
  text,
  header,
  handleTextInputChange,
  txtInputDisable,
  buttonFunction,
  buttonVisibility,
}) {
  const StyledInput = styled('MDInput')({
    ':disabled': {
      backgroundColor: 'white',
    },
  });
  return (
    <Grid item xs={12} md={6} lg={4}>
      <MDBox>
        <Card>
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            rowGap={2}
            pt={1}
            pb={2}
            px={2}
            maxHeight="325px"
            style={{ minHeight: "325px" }}
          >
            
            <MDBox display="flex" justifyContent="space-between" style={{ minHeight:"37px"}}>
              <MDBox display="flex" flexDirection="row" gap={4} alignItems="center">
                <MDTypography variant="h5">{header}</MDTypography>
              </MDBox>
              <MDBox display="flex" gap={2}>
                {buttonVisibility && 
                  <MDButton variant="gradient" color="dark" onClick={() => buttonFunction()}>
                    Speichern
                  </MDButton>
                }
              </MDBox>
            </MDBox>
            <MDInput
              label={txtInputDisable ? "" : "Text hier einfügen ..."}
              multiline
              rows={11}
              value={text}
              onChange={(e) => handleTextInputChange(e)}
              disabled={txtInputDisable}
            />
          </MDBox>
        </Card>
      </MDBox>
    </Grid>
  );
}

// Setting default values
InputTextCard.defaultProps = {
  text: "",
  header: "",
};

// Typechecking props 
InputTextCard.propTypes = {
  text: PropTypes.string,
  header: PropTypes.string,
};

export default InputTextCard;
