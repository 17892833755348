import React, { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";

function InputTextCardOriginalText({
  text,
  header,
  handleTextInputChange,
  txtInputDisable,
  tabValue,
  setTabValue,
  enableTabValue,
}) {
  const handleSetTabValue = (event, newValue) => {
    if (enableTabValue) {
      setTabValue(newValue);
    }
  }
  const StyledInput = styled('MDInput')({
    padding:"20px"
  });
  return (
    <Grid item xs={12} md={6} lg={4}>
      <MDBox>
        <Card>
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            rowGap={2}
            pt={1}
            pb={2}
            px={2}
            maxHeight="325px"
            style={{ minHeight: "325px" }}
          >
            
            <MDBox display="flex" justifyContent="space-between" style={{ minHeight:"37px"}}>
              <MDBox display="flex" flexDirection="row" gap={4} alignItems="center">
                <MDTypography variant="h5">{header}</MDTypography>
              </MDBox>
              <AppBar position="static" style={{ maxWidth:"50%", marginRight:"5px" }}>
                <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
                  <Tab
                    label="Modell 1"
                  />
                  <Tab
                    label="Modell 2"
                  />
                </Tabs>
              </AppBar>
            </MDBox>
            <MDInput
              label={txtInputDisable ? "" : "Text hier bearbeiten ..."}
              multiline
              rows={11}
              value={text}
              onChange={(e) => handleTextInputChange(e)}
              // disabled={txtInputDisable}
            />
          </MDBox>
        </Card>
      </MDBox>
    </Grid>
  );
}

// Setting default values
InputTextCardOriginalText.defaultProps = {
  text: "",
  header: "",
};

// Typechecking props 
InputTextCardOriginalText.propTypes = {
  text: PropTypes.string,
  header: PropTypes.string,
};

export default InputTextCardOriginalText;
