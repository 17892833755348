import React from "react";
import PropTypes from "prop-types";
import MDButton from "components/MDButton";
import { styled } from "@mui/material/styles";

// Styles for the Popup overlay
const Overlay = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 9999,
});

// Styles for the Popup container
const PopupContainer = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.borders.borderRadius.md,
  boxShadow: theme.shadows[5],
  width: "600px",
  padding: theme.spacing(2, 4, 3),
  position: "relative",
}));

// Styles for the Close Button
const CloseButton = styled(MDButton)({
  position: "absolute",
  top: "8px",
  right: "8px",
  fontsize: "20px",
  minWidth: "auto",
  padding: "8px",
});

// Styles for the Footer with Buttons
const Footer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "20px",
});

function Popup({ title, content, buttonLableLeft, buttonLableRight, buttonFunctionLeft, buttonFunctionRight }) {
  return (
    <Overlay>
      <PopupContainer>
        {/* <CloseButton onClick={onSave}>×</CloseButton> */}
        <div>
          <h3>{title}</h3>
          <div>
            <p>{content}</p>
          </div>
          <Footer>
            <MDButton variant="gradient" color="dark" onClick={buttonFunctionLeft}>{buttonLableLeft}</MDButton>
            <MDButton variant="gradient" color="dark" onClick={buttonFunctionRight}>{buttonLableRight}</MDButton>
          </Footer>
        </div>
      </PopupContainer>
    </Overlay>
  );
}

// Setting default values for the props of Popup
Popup.defaultProps = {
  title: "Title",
  content: "Content Content Content Content Content Content ",
  onClose: () => {},
  onSave: () => {},
};

// Typechecking props of the Popup
Popup.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default Popup;
