import React, { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Additional components (React Spinner)
import HashLoader from "react-spinners/HashLoader";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

function ShowCardKI({
  text,
  header,
  loading,
  startModel1,
  startModel2,
  tabValue,
  buttonVisibility,
  numberOfRuns,
  maxNumberOfRuns,
}) {
  const buttonFunction = async() => {
    if (tabValue==0) {
      await startModel1();
    }
    else if (tabValue==1) {
      await startModel2();
    }
  }
  return (
    <Grid item xs={12} md={6} lg={4}>
      <MDBox>
        <Card>
          <MDBox
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            rowGap={1}
            pt={1}
            pb={1}
            px={2}
            maxHeight="325px"
            style={{ minHeight: "325px" }}
          >
            <MDBox display="flex" justifyContent="space-between">
              <MDBox display="flex" flexDirection="row" gap={4} alignItems="center">
                  <MDTypography variant="h5">{header}</MDTypography>
                  <MDTypography variant="Caption" color="warning">{`${numberOfRuns} / ${maxNumberOfRuns}`}</MDTypography>
              </MDBox>
              <MDBox display="flex" gap={2}>
                {buttonVisibility && 
                  <MDButton variant="gradient" color="dark" onClick={buttonFunction}>
                    Start
                  </MDButton>
                }
              </MDBox>
            </MDBox>
              {loading ? (
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    marginBottom:10,
                    marginTop:10,
                    height:1000,
                    maxHeight: "100%",
                    overflowY: "auto",
                    border: "1px solid #dee2e6"
                  }}
                >
                  <HashLoader
                    color="#dee2e6"
                    loading={loading}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </MDBox>
              ) : (
                <MDBox
                  borderRadius="lg"
                  padding="10px 40px 0px 25px"
                  style={{
                    marginBottom:10,
                    marginTop:10,
                    height:1000,
                    maxHeight: "100%",
                    overflowY: "auto",
                    border: "1px solid #dee2e6"
                  }}
                >
                  {text && (
                    <MDTypography
                      fontWeight="light"
                      style={{
                        fontSize: 14,
                        color:"#495057",
                        lineHeight: "20px"
                      }}
                    >
                      {text}
                    </MDTypography>
                  )}
                </MDBox>
              )}
          </MDBox>
        </Card>
      </MDBox>
    </Grid>
  );
}

// Setting default values
ShowCardKI.defaultProps = {
  text: "",
  header: "",
};

// Typechecking props 
ShowCardKI.propTypes = {
  text: PropTypes.string,
  header: PropTypes.string,
};

export default ShowCardKI;
