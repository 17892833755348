import getHeader from "./header";

const baseURL = process.env.REACT_APP_EXPRESS_API + "/api/keycloak";

// login
export async function login(body, { loginUser }) {
  try {
    const response = await fetch(baseURL + "/login", {
      method: "POST",
      body: JSON.stringify(body),
      headers: getHeader(),
    });
    const json = await response.json();
    if (!response.ok) {
      console.log("response expressKeycloak failed");
      return false;
    }
    if (json.error) {
      console.error("Login error:", json.error_description || "Unknown error");
      return false;
    }
    if (response.ok) {      
      const body_userinfo = { token: json.access_token };
      const response_userinfo = await fetch(baseURL + "/userinfo", {
        method: "POST",
        body: JSON.stringify(body_userinfo),
        headers: getHeader(),
      });
      const json_userinfo = await response_userinfo.json();
      // Save tokens + user information in context and cookies
      loginUser(
        { 
          accessToken: json.access_token,
          refreshToken: json.refresh_token
        },
        {
          first_name: json_userinfo.given_name,
          last_name: json_userinfo.family_name,
          email: json_userinfo.email,
          user_id: json_userinfo.id,
        }
      );
      return true;
    }
  } catch (error) {
    console.log("Login not working currently", error);
    return false;
  }
};

// introspect
async function introspect(body) {

  const response = await fetch(baseURL + "/introspect", {
    method: "POST",
    body: JSON.stringify(body),
    headers: getHeader(),
  });
  const json = await response.json();
  if (!response.ok) {
    console.log("response expressKeycloak failed");
  }
  if (response.ok) {
    return json;
  }
};

// refresh
async function refresh(body) {

  const response = await fetch(baseURL + "/refresh", {
    method: "POST",
    body: JSON.stringify(body),
    headers: getHeader(),
  });
  const json = await response.json();
  if (!response.ok) {
    console.log("response expressKeycloak failed");
  }
  if (response.ok) {
    return json;
  }
};

// logout
export async function logout({ tokens }) {
  const body = { token: tokens.refreshToken };
  const response = await fetch(baseURL + "/logout", {
    method: "POST",
    body: JSON.stringify(body),
    headers: getHeader(),
  });
  const json = await response.json();
  if (!response.ok) {
    console.log(JSON.stringify(json,null,2))
    console.log("response expressKeycloak failed");
  }
  if (response.ok) {
    return json;
  }
};

export async function checkRefreshToken (tokens) {
  try {
    
    const introspecs = await introspect({ token: tokens.refreshToken });
    if (introspecs.active) {
      const tempTokens = await refresh({ token: tokens.refreshToken });
      // Save tokens in cookies
      const newTokens = 
        { 
          accessToken: tempTokens.access_token,
          refreshToken: tempTokens.refresh_token
        };

      return newTokens;
    } else {
      return null
    }
  } catch (error) {
    console.log(error);
    return null
  }
};

// createUser
export async function createUser(body) {

  const response = await fetch(baseURL + "/createuser", {
    method: "POST",
    body: JSON.stringify(body),
    headers: getHeader(),
  });

  if (!response.ok) {
    console.log("response expressKeycloak failed");
    return "response expressKeycloak failed";
  }
  if (response.ok ) {
    const json_response = await response.json();

    if (response.status === 200) {
      return json_response.message;
    }
    return json_response.errorMessage;
  }
};