import React, { useState, useEffect }  from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import breakpoints from "assets/theme/base/breakpoints";

// Additional components (React Spinner)
import HashLoader from "react-spinners/HashLoader";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

// Own components
import TextLabeling from "examples/TextLabeling";

function AnnotationKICard({
  tokens,
  header,
  taggedTokens,
  buttonVisibility,
  buttonThumbsUpDown,
  thumbsUpDown,
  modelNumber,
  startDeidentification,
}) 
{
  const [load, setLoad] = useState(false);

  const entities = [
    { tag: "Original", color: "transparent" },
    { tag: "<NAME>", color: "#ffd966" },
    { tag: "<AGE>", color: "#8dc6ff" },
    { tag: "<LOCATION>", color: "#a0ebaa" },
    { tag: "<CONTACT>", color: "#ff8a85" },
    { tag: "<ID>", color: "#6d7f9f" },
    { tag: "<DATE>", color: "#ffc0cb" },
  ];

  const start = async () => {
    setLoad(true);
    await startDeidentification(modelNumber);
    setLoad(false);
  }
  const thumbUp = () => {
    buttonThumbsUpDown(modelNumber, 0);
  }

  const thumbDown = () => {
    buttonThumbsUpDown(modelNumber, 1);
  }
  return (
    <Grid item xs={12} md={6} lg={6}>
      <MDBox>
        <Card>
          <MDBox
            display="flex"
            flexDirection="column"
            py={1}
            px={2}
            maxHeight="325px"
            style={{ minHeight: "325px" }}
          >
            <MDBox display="flex" justifyContent="space-between">
              <MDTypography variant="h5">{header}</MDTypography>
              <MDBox display="flex" gap={2}>
                {buttonVisibility && 
                  <MDButton variant="gradient" color="dark" onClick={() => start()}>
                    Start
                  </MDButton>
                }
              </MDBox>
            </MDBox>
            {load ? (
              <MDBox
                borderRadius="lg"
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  marginBottom:10,
                  marginTop:10,
                  height:1000,
                  maxHeight: "100%",
                  overflowY: "auto",
                  border: "1px solid #dee2e6"
                }}
              >
                <HashLoader
                  color="#dee2e6"
                  loading={load}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </MDBox>
            ) : (
              <MDBox
                borderRadius="lg"
                padding="10px 40px 0px 25px"
                style={{
                  marginBottom:10,
                  marginTop:10,
                  height:1000,
                  maxHeight: "100%",
                  overflowY: "auto",
                  border: "1px solid #dee2e6"
                }}
              >
                {!buttonVisibility && 
                  <MDBox display="flex" flexDirection="column" gap={1}>
                    <MDBox display="flex" flexDirection="row" gap="2px">
                      {entities.slice(1).map((entity, index) => (
                        <MDBox
                          key={`tag-${index}`}
                          bgColor={entity.color}
                          style={{ height: "20px", fontSize: "14px" }}
                        >
                          {entity.tag}
                        </MDBox>
                      ))}
                    </MDBox>
                    <MDBox
                      display="flex"
                      flexDirection="row"
                      flexWrap="wrap"
                    >
                      {tokens.map((word, index) => {
                        return (
                          <MDBox
                            key={`wordBox-${index}`}
                            px="1px"
                            bgColor={
                              taggedTokens[index] === "<NAME>"
                                ? entities[1].color
                                : taggedTokens[index] === "<AGE>"
                                ? entities[2].color
                                : taggedTokens[index] === "<LOCATION>"
                                ? entities[3].color
                                : taggedTokens[index] === "<CONTACT>"
                                ? entities[4].color
                                : taggedTokens[index] === "<ID>"
                                ? entities[5].color
                                : taggedTokens[index] === "<DATE>"
                                ? entities[6].color
                                : entities[0].color
                            }
                            position="relative"
                            display="flex"
                            style={{ cursor: "text" }}
                          >
                            <MDTypography variant="body2">{word}</MDTypography>
                          </MDBox>
                        );
                      })}
                    </MDBox>
                  </MDBox>
                }
              </MDBox>
            )}
            <MDBox display="flex" gap={1} style={{ left:"auto", right:"30px", top:"auto", bottom:"25px", position:"absolute" }}>
              <ThumbUpIcon
                onClick={thumbUp}
                style={{
                  color:thumbsUpDown[modelNumber][0] ? "green" : "gray",
                  cursor:"pointer"
                }}
              />
              <ThumbDownIcon
                onClick={thumbDown}
                style={{
                  color:thumbsUpDown[modelNumber][1] ? "red" : "gray",
                  cursor:"pointer"
                }}
              />
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </Grid>
    
  );
}

// Setting default values
AnnotationKICard.defaultProps = {
  deidentifiedText: "",
  loading: false,
};

// Typechecking props 
AnnotationKICard.propTypes = {
  deidentifiedText: PropTypes.string,
  loading: PropTypes.bool,
};

export default AnnotationKICard;
