export default function getHeader() {
  const basicUsername = process.env.REACT_APP_BASIC_AUTH_USERNAME;
  const basicPassword = process.env.REACT_APP_BASIC_AUTH_PASSWORD;

  if (!basicUsername || !basicPassword) {
    console.warn("Missing basic authentication environment variables.");
    return {};
  }

  const credentials = `${basicUsername}:${basicPassword}`;
  const base64Credentials = btoa(credentials);

  return {
    "Content-Type": "application/json",
    Authorization: `Basic ${base64Credentials}`,
    Accept: "application/json",
  };
}