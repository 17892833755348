import { useState, useEffect } from "react";
import { toast } from 'react-toastify';

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";

// Own components
import InputTextCard from "./components/InputTextCard";
import InputTextCardOriginalText from "./components/InputTextCardOriginalText";
import AnnotationManualCard from "./components/AnnotationManualCard";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Handler
import ExpressHandler from "handler/expressHandler";
import LlmBackendHandler from "handler/llmBackendHandler";
import RbBackendHandler from "handler/rbBackendHandler";
import TokenizerBackendHandler from "handler/tokenizerBackendHandler";
import FastAPIHandler from "handler/fastapiHandler";
import ExpressKeycloakHandler from "handler/expressKeycloakHandler";


function Lc_testset() {
  const [inputText, setInputText] = useState("");
  const [inputPostprocessingText, setInputPostprocessingText] = useState("");
  const [tokens, setTokens] = useState([]);
  const [txtInputDisable, setTxtInputDisable] = useState([false, false]);
  const [buttonsVisibility, setButtonsVisibility] = useState([true, false]);
  const [loading, setLoading] = useState(false);

  const expressHandler = new ExpressHandler();
  const expressKeycloakHandler = new ExpressKeycloakHandler();
  const fastapiHandler = new FastAPIHandler();
  const llmBackendHandler = new LlmBackendHandler();
  const rbBackendHandler = new RbBackendHandler();
  const tokenizerBackendHandler = new TokenizerBackendHandler();

  const handleTextInputChange = (e) => {
    setInputText(e.target.value);
  };
  const handlePostprocessingInputChange = (e) => {
    setInputPostprocessingText(e.target.value);
  };

  const tokenize = async () => {
    setButtonsVisibility([false, false]);
    setLoading(true);
    
    // Get the tokens from inputText
    const tokens = await tokenizerBackendHandler.tokenize()


  }

  const speichern = () => {
    console.log("speichern ins Backend");
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container spacing={5} rowSpacing={3}>
          <InputTextCardOriginalText
            text={inputText}
            header="Originaler Text"
            handleTextInputChange={handleTextInputChange}
            txtInputDisable={txtInputDisable[0]}
            buttonFunction={tokenize}
            buttonVisibility={buttonsVisibility[0]} />
          <AnnotationManualCard
            header="Menschliche Annotation"
            tokens={tokens}
            setTokens={setTokens}
            loading={loading}
            taggedTokensManual={true}
            setTaggedTokensManual={true} />
          <InputTextCard
            text={inputPostprocessingText}
            header="Nachbearbeitung"
            handleTextInputChange={handlePostprocessingInputChange}
            txtInputDisable={txtInputDisable[1]}
            buttonFunction={speichern}
            buttonVisibility={buttonsVisibility[1]} />
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Lc_testset;
