/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

// Own components
// import keycloakHandler from "handler/keycloakHandler";
import ExpressKeycloakHandler from "handler/expressKeycloakHandler";

function Basic() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [toastSuccess, setToastSuccess] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [toastTitle, setToastTitle] = useState("Textfeld leer");
  const [toastContent, setToastContent] = useState("Textfeld hat keinen Inhalt");

  const expressKeycloakHandler = new ExpressKeycloakHandler();

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  // Toast Objects + Logic
  const openToastSuccess = (titel, content) => {
    setToastTitle(titel);
    setToastContent(content);
    setToastSuccess(true);
  };
  const closeToastSuccess = () => setToastSuccess(false);
  const openToastError = (titel, content) => {
    setToastTitle(titel);
    setToastContent(content);
    setToastError(true);
  };
  const closeToastError = () => setToastError(false);

  const renderSuccess = (
    <MDSnackbar
      color="succcess"
      icon="check"
      title={toastTitle}
      content={toastContent}
      dateTime=""
      open={toastSuccess}
      onClose={closeToastSuccess}
      close={closeToastSuccess}
      bgWhite
    />
  );

  const renderError = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={toastTitle}
      content={toastContent}
      dateTime=""
      open={toastError}
      onClose={closeToastError}
      close={closeToastError}
      bgWhite
    />
  );

  const onPageLoad = async () => {
    const refresh_token = expressKeycloakHandler.getCookie("lc_refresh_token");
    if (refresh_token) {
      await expressKeycloakHandler.checkRefreshToken();
      window.location.href = window.location.origin + "/dashboard";
    }
  }
  useEffect(() => {
    onPageLoad();
  }, []);

  const login = async () => {
    if (email !== "" && password !== "") {
      const tokens = await expressKeycloakHandler.login({ username: email, password: password });
      if (tokens !== undefined) {
        openToastSuccess("Anmeldung Erfolgreich", "");
        setTimeout(function () {
          window.location.href = window.location.origin + "/dashboard";
        }, 1000);
      }
      else {
        openToastError("Email oder Passwort falsch", "Probiere es nochmal");
      }
    } else {
      openToastError("Email oder Passwort fehlt", "Probiere es nochmal");
    }
  }
  return (
    <BasicLayout image={bgImage}>
      {renderSuccess}
      {renderError}
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Anmelden
          </MDTypography>

        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="email" label="Email" fullWidth value={email} onChange={(e) => setEmail(e.target.value)} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    login();
                  }
                }}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Angemeldet bleiben
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={login}>
                Anmelden
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Noch keinen Account?{" "}
                <a href="mailto:contact@abcgen.ai?subject=Registrierung%20zu%20Deidentifikationstool" style={{ color: '#1662C4', fontWeight: 'bold', textDecoration: 'none' }}>
                  Registrieren
                </a>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
