import React, { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import breakpoints from "assets/theme/base/breakpoints";

// Additional components (React Spinner)
import HashLoader from "react-spinners/HashLoader";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Own components
import TextLabeling from "examples/TextLabeling";

function AnnotationManualCard({
  header,
  tokens,
  setTokens,
  loading,
  taggedTokensManual,
  setTaggedTokensManual,
}) {
  return (
    <Grid item xs={12} md={6} lg={4}>
      <MDBox>
        <Card>
          <MDBox
            display="flex"
            flexDirection="column"
            py={1}
            px={2}
            maxHeight="325px"
            style={{ minHeight: "325px" }}
          >
            <MDBox display="flex" gap="10%" alignItems="center" style={{ maxWidth: "100%" }}>
              <MDTypography variant="h5">{header}</MDTypography>
            </MDBox>
            {loading ? (
              <MDBox
                borderRadius="lg"
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  marginBottom: 10,
                  marginTop: 10,
                  height: 1000,
                  maxHeight: "100%",
                  overflowY: "auto",
                  border: "1px solid #dee2e6"
                }}
              >
                <HashLoader
                  color="#dee2e6"
                  loading={loading}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </MDBox>
            ) : (
              <MDBox
                borderRadius="lg"
                padding="10px 40px 0px 25px"
                style={{
                  marginBottom: 10,
                  marginTop: 10,
                  height: 1000,
                  maxHeight: "100%",
                  overflowY: "auto",
                  border: "1px solid #dee2e6"
                }}
              >
                <TextLabeling
                  tokens={tokens}
                  setTokens={setTokens}
                  taggedEntities={taggedTokensManual}
                  setTaggedEntities={setTaggedTokensManual}
                />
              </MDBox>
            )}
          </MDBox>
        </Card>
      </MDBox>
    </Grid>

  );
}

// Setting default values
AnnotationManualCard.defaultProps = {
  deidentifiedText: "",
  loading: false,
};

// Typechecking props 
AnnotationManualCard.propTypes = {
  deidentifiedText: PropTypes.string,
  loading: PropTypes.bool,
};

export default AnnotationManualCard;
