import { createContext, useContext, useState, useEffect } from "react";
// import CookieBanner from "../components/CookieBanner/CookieBanner";

const StudieContext = createContext();
export const useStudieContext = () => useContext(StudieContext);

export const StudieContextProvider = ({ children }) => {

  const [studieData, setStudieData] = useState(
    {
      originalText: "",
      autoAnonymizedText:"",
      anonymizedText: "",
      backendData: {},
      ranking: {
        autoAnonym: "",
        reident: "",
      },
      withAi: false,
      timeManualAnonym: "", 
      userinfo: {},
    }
  );

  return (
    <StudieContext.Provider
      value={{
        studieData,
        setStudieData,
      }}>
      {children}
    </StudieContext.Provider>
  );
};
