class RbBackendHandler {
  // baseURL = "http://81.89.199.178:5004/api/backend/rulebased";
  baseURL = "https://xp-kc.covid-companion.de/api/backend/rulebased";
  basicUsername = process.env.REACT_APP_BASIC_USERNAME;
  basicPassword = process.env.REACT_APP_BASIC_PASSWORD;
  // login
  async rbNer(body) {
    // expects body={username:String, password:String}
    const credentials = `${this.basicUsername}:${this.basicPassword}`;
    const base64Credentials = btoa(credentials);

    const response = await fetch(this.baseURL + "/rbner", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${base64Credentials}`
      },
    });
    const json = await response.json();
    if (!response.ok) {
      console.log("response expressKeycloak failed");
    }
    if (response.ok) {
      console.log("responce expressKeycloak successfull");
      return json;
    }
  };
}

export default RbBackendHandler;