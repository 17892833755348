class LlmBackendHandler {
  // base_url = "https://longcovid-backend.jf29medma.com/process_text";
  base_url = "https://xp-kc.covid-companion.de/api/backend/llm";
  // base_url = "http://81.89.199.166:5004/api/backend/llm";
  basicUsername = process.env.REACT_APP_BASIC_USERNAME;
  basicPassword = process.env.REACT_APP_BASIC_PASSWORD;

  async deidLlmBackend(inputText, model, variant, step, max_tokens=4000) {

    const credentials = `${this.basicUsername}:${this.basicPassword}`;
    const base64Credentials = btoa(credentials);
    const header = {
      "Content-Type": "application/json",
      Accepts: "application/json",
      "Authorization": `Basic ${base64Credentials}`
    };

    const body = {
      "input_text": inputText,
      "max_tokens": max_tokens,
      "model_name": "thewimo:deid-llama3-q8_0",
      "step": "entity_identification",
    }
    try {
      const response = await fetch(this.base_url + "/getresponse", {
        method: "POST",
        body: JSON.stringify(body),
        headers: header,
      });
      const json = await response.json();
      if (!response.ok) {
        console.log("error posting to llmBackend server");
      }
      if (response.ok) {
        console.log("llmBackend successfully processed", json);
        return json;
      }
    } catch (e) {
      console.log("error posting to llmBackend server", e);
    }
  }

  async anonymLlmBackend(inputText, deidInference, max_tokens=4000) {

  const credentials = `${this.basicUsername}:${this.basicPassword}`;
  const base64Credentials = btoa(credentials);
  const header = {
    "Content-Type": "application/json",
    Accepts: "application/json",
    "Authorization": `Basic ${base64Credentials}`
  };

    const body = {
      "grammar_corrected_text": inputText,
      "identified_entities": deidInference,
      "model_name": "taozhiyuai/hermes-2-theta-llama-3:8b-q8_0",
      "max_tokens": 4000,
      "step": "deidentification_anonymization",
      "model_variant": "non-finetuned"
    }
    console.log(body);
    try {
      const response = await fetch(this.base_url + "/getresponse", {
        method: "POST",
        body: JSON.stringify(body),
        headers: header,
      });
      const json = await response.json();
      if (!response.ok) {
        console.log("error posting to llmBackend server");
      }
      if (response.ok) {
        console.log("llmBackend successfully processed", json);
        return json;
      }
    } catch (e) {
      console.log("error posting to llmBackend server", e);
    }
  }
}

export default LlmBackendHandler;
