export const tabValues = [
  [
    { "number": 1, "label": "Hat mehr Arbeit verursacht" },
    { "number": 2, "label": "Hat Arbeit erschwert" },
    { "number": 3, "label": "Keine Hilfe" },
    { "number": 4, "label": "Wenig hilfreich" },
    { "number": 5, "label": "Neutral" },
    { "number": 6, "label": "Etwas hilfreich" },
    { "number": 7, "label": "Hilfreich" },
    { "number": 8, "label": "Sehr hilfreich" },
    { "number": 9, "label": "Hat fast alles übernommen" },
    { "number": 10, "label": "Hat alles übernommen" }
  ],
  [
    { "number": 1, "label": "Eindeutig re-identifizierbar" },
    { "number": 2, "label": "Hochgradig re-identifizierbar" },
    { "number": 3, "label": "Möglich, mit Zusatzinformationen" },
    { "number": 4, "label": "Teilweise re-identifizierbar" },
    { "number": 5, "label": "Unklar / Neutral" },
    { "number": 6, "label": "Eher nicht re-identifizierbar" },
    { "number": 7, "label": "Kaum re-identifizierbar" },
    { "number": 8, "label": "Nicht re-identifizierbar" },
    { "number": 9, "label": "Vollständig anonymisiert" },
    { "number": 10, "label": "Keine personenbezogenen Daten enthalten" }
  ],
]