import React, { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Additional components (React Spinner)
import HashLoader from "react-spinners/HashLoader";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

function ShowCardKI({
  text,
  header,
  loading,
}) {
  return (
    <Grid item xs={12} md={6} lg={4}>
      <MDBox>
        <Card>
          <MDBox
            display="flex"
            flexDirection="column"
            rowGap={1}
            py={1}
            px={2}
            maxHeight="325px"
            style={{ minHeight: "325px" }}
          >
            <MDBox display="flex">
              <MDTypography variant="h5">{header}</MDTypography>
            </MDBox>
              {loading ? (
                <MDBox
                  borderRadius="lg"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    marginBottom:10,
                    marginTop:10,
                    height:1000,
                    maxHeight: "100%",
                    overflowY: "auto",
                    border: "1px solid #dee2e6"
                  }}
                >
                  <HashLoader
                    color="#dee2e6"
                    loading={loading}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </MDBox>
              ) : (
                <MDBox
                  borderRadius="lg"
                  padding="12px 12px 0px 12px"
                  style={{
                    marginBottom:10,
                    marginTop:10,
                    height:1000,
                    maxHeight: "100%",
                    overflowY: "auto",
                    border: "1px solid #dee2e6"
                  }}
                >
                  {text && (
                    <MDTypography
                      fontWeight="light"
                      style={{
                        fontSize: 14,
                        color:"#495057",
                        lineHeight: "20px"
                      }}
                    >
                      {text}
                    </MDTypography>
                  )}
                </MDBox>
              )}
          </MDBox>
        </Card>
      </MDBox>
    </Grid>
  );
}

// Setting default values
ShowCardKI.defaultProps = {
  text: "",
  header: "",
};

// Typechecking props 
ShowCardKI.propTypes = {
  text: PropTypes.string,
  header: PropTypes.string,
};

export default ShowCardKI;
