import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PieChart from "examples/Charts/PieChart";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import Popup from "examples/Popup";


function Lc_dashboard() {
  const [alertResponse, setAlertResponse] = useState(false);

  const handleProceed = () => {
    setAlertResponse(true);
    console.log("Button clicked, alertResponse set to true");
  };

  const handleStay = () => {
    setAlertResponse(true);
    console.log("Button clicked, alertResponse set to true");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <DefaultInfoCard
                icon="account_balance"
                title="Gesamtfälle"
                description="Alle importierten Fälle"
                value="59"
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <DefaultInfoCard
                icon="import_contacts"
                title="Offene Fälle"
                description="Noch zu bearbeiten"
                value="40"
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <VerticalBarChart
                icon={{ color: "info", component: "leaderboard" }}
                title="Bearbeitungsstatus"
                description="Balkendiagramm"
                chart={{
                  labels: ["Neue Fälle", "Fälle in Bearbeitung", "Fälle abgeschlossen"],
                  datasets: [{
                    label: "Status",
                    color: "dark",
                    data: [31, 9, 19],
                  }],
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <PieChart
                icon={{ color: "info", component: "leaderboard" }}
                title="KI identifiziert"
                description="in Prozent"
                chart={{
                  labels: ["abgeschlossen", "kritisch"],
                  datasets: {
                    label: "Prozent",
                    backgroundColors: ["success", "error"],
                    data: [85, 15],
                  },
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Lc_dashboard;
