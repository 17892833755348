import React, { useState, useEffect, useReducer } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";

// Own components
import getImportData from "./data/getImportData";
import DeidProzess from "./components/deidProzess/deidProzess";
import DeidProzessManuell from "./components/deidProzessManuell/deidProzessManuell";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Handler
import ExpressHandler from "handler/expressHandler";

function Lc_falluebersicht() {
  const expressHandler = new ExpressHandler();
  const [docColoumnData, setDocColoumnData] = useState(null);
  const [docRowData, setDocRowData] = useState(null);
  const [deidProzessToggle, setDeidProzessToggle] = useState(false);
  const [deidManualToggle, setDeidManualToggle] = useState(false);

  // Variables for deidentification
  const [deidentifyTitle, setDeidentifyTitle] = useState("");
  const [deidentifyFallnummer, setDeidentifyFallnummer] = useState("");
  const [inputText_01, setInputText_01] = useState("");
  const [inputText_02, setInputText_02] = useState("");
  const [inputText_03, setInputText_03] = useState("");
  const [inputText_04, setInputText_04] = useState("");
  const [inputText_05, setInputText_05] = useState("");
  const [inputText_06, setInputText_06] = useState("");
  const [importedDataNumber, setImportedDataNumber] = useState(0);
  const [importedDataId, setImportedDataId] = useState("");

  const deidentifyData = async (index) => {
    setImportedDataNumber(index);
    setImportedDataId(tableData[index]._id);
    setDeidentifyTitle(tableData[index].titel);
    setDeidentifyFallnummer(tableData[index].fallnummer);
    setInputText_01(tableData[index].originalFallbeschreibung);
    setInputText_02(tableData[index].originalNichtGutVerlaufen);
    setInputText_03(tableData[index].originalBesondersGutPositiv);
    setInputText_04(tableData[index].originalBeitragendeFaktoren);
    setInputText_05(tableData[index].originalAusFallLernen);
    setInputText_06(tableData[index].originalWeitereGedanken);
    setDeidProzessToggle(true);
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  const [tableData, setTableData] = useReducer((state, action) => {
    switch (action.type) {
      case "LOAD_DATA_FROM_MONGODB": {
        // page load (retrieve files from mongoDB)
        const { columns: colData, rows: rowData } = getImportData(action.payload, deidentifyData);
        setDocColoumnData(colData);
        setDocRowData(rowData);
        return [...state, ...action.payload];
      }
      case "DELETE_FILE": {
        // TODO removing
        // TODO toast successfully removed
        console.log("Successfully removed");
      }
      default: {
        const { columns: colData, rows: rowData } = getImportData(action.payload, deidentifyData);
        setDocColoumnData(colData);
        setDocRowData(rowData);
        return [...state, ...action.payload];
      }
    }
  }, []);
  // page load
  const onPageLoad = async () => {
    chooseRandomDeidentificationMethod();
    if (tableData.length === 0) {
      const response_express = await expressHandler.getImportData();
      setTableData({ type: "LOAD_DATA_FROM_MONGODB", payload: response_express });
    }
  };
  useEffect(() => {
    onPageLoad();
  }, []);
  const nextData = () => {
    chooseRandomDeidentificationMethod();
    if (importedDataNumber == tableData.length-1 ) {
      deidentifyData(0);
      setImportedDataNumber(0);
    } else {
      deidentifyData(importedDataNumber+1);
      setImportedDataNumber(importedDataNumber+1);
    }
  }
  const prevData = () => {
    chooseRandomDeidentificationMethod();
    if (importedDataNumber == 0) {
      deidentifyData(tableData.length-1);
      setImportedDataNumber(tableData.length-1);
    } else {
      deidentifyData(importedDataNumber-1);
      setImportedDataNumber(importedDataNumber-1);
    }
  }
  const chooseRandomDeidentificationMethod = () => {
    setDeidManualToggle(Math.random() < 0.5);
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox>
        <Grid container spacing={5} rowSpacing={5}>
          <Grid item xs={12}>
            {deidProzessToggle ? 
              deidManualToggle ?
                <DeidProzessManuell
                  title={deidentifyTitle}
                  fallnummer={deidentifyFallnummer}
                  inputText_01={inputText_01}
                  inputText_02={inputText_02}
                  inputText_03={inputText_03}
                  inputText_04={inputText_04}
                  inputText_05={inputText_05}
                  inputText_06={inputText_06}
                  setInputText_01={setInputText_01}
                  setInputText_02={setInputText_02}
                  setInputText_03={setInputText_03}
                  setInputText_04={setInputText_04}
                  setInputText_05={setInputText_05}
                  setInputText_06={setInputText_06}
                  setDeidProzessToggle={setDeidProzessToggle}
                  deidProzessToggle={deidProzessToggle}
                  nextData={nextData}
                  prevData={prevData}
                  importedDataId={importedDataId}
                  chooseRandomDeidentificationMethod={chooseRandomDeidentificationMethod}
                  setTableData={setTableData}
                />
              :
                <DeidProzess
                  title={deidentifyTitle}
                  fallnummer={deidentifyFallnummer}
                  inputText_01={inputText_01}
                  inputText_02={inputText_02}
                  inputText_03={inputText_03}
                  inputText_04={inputText_04}
                  inputText_05={inputText_05}
                  inputText_06={inputText_06}
                  setInputText_01={setInputText_01}
                  setInputText_02={setInputText_02}
                  setInputText_03={setInputText_03}
                  setInputText_04={setInputText_04}
                  setInputText_05={setInputText_05}
                  setInputText_06={setInputText_06}
                  setDeidProzessToggle={setDeidProzessToggle}
                  deidProzessToggle={deidProzessToggle}
                  nextData={nextData}
                  prevData={prevData}
                  importedDataId={importedDataId}
                  chooseRandomDeidentificationMethod={chooseRandomDeidentificationMethod}
                  setTableData={setTableData}
                /> 
            :
              <Card>
                {docColoumnData && (
                  <DataTable
                    table={{ columns: docColoumnData, rows: docRowData }}
                    isSorted={false}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    noEndBorder
                    tempPage={3}
                  />
                )}
              </Card>
            }
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Lc_falluebersicht;
