import React from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

function OrginaltextCard({ inputValue, handleTextInputChange }) {
  return (
    <Grid item xs={12} md={6} lg={6}>
      <MDBox>
        <Card>
          <MDBox
            display="flex"
            flexDirection="column"
            rowGap={2}
            py={1}
            px={2}
            maxHeight="325px"
            style={{ minHeight: "325px" }}
          >
            <MDTypography variant="h5">Originaler Text</MDTypography>
            <MDInput
              label="Text hier einfügen ..."
              multiline
              rows={11}
              value={inputValue}
              onChange={(e) => handleTextInputChange(e)}
            />
          </MDBox>
        </Card>
      </MDBox>
    </Grid>
  );
}

// Setting default values
OrginaltextCard.defaultProps = {
  handleTextInputChange: () => {},
  inputValue: "",
};

// Typechecking props 
OrginaltextCard.propTypes = {
  handleTextInputChange: PropTypes.func.isRequired,
  inputValue: PropTypes.string,
};

export default OrginaltextCard;
