// Class to connect React with FastAPI
class FastAPIHandler {
  // base_url = "http://81.89.199.154/";
  base_url = "https://be-jsl.covid-companion.de/";
  header = {
    "Content-Type": "application/json",
    Accepts: "application/json",
  };
  async getStatus() {
    try {
      const response = await fetch(this.base_url, {
        method: "GET",
        headers: this.header,
      });
      const json = await response.json();
      if (!response.ok) {
        console.log("dag(s) could not be fetched from Airflow");
      }
      if (response.ok) {
        console.log("dags successfully fetched from Airflow", json);
        return json;
      }
    } catch (e) {
      console.log("error fetching from fastapi server", e);
    }
  }
  async postId(body) {
    const url = this.base_url + "deid";
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: this.header,
      });
      const json = await response.json();
      if (!response.ok) {
        console.log("error posting to fastapi server");
      }
      if (response.ok) {
        console.log("backend successfully recieved ID", json);
        return json;
      }
    } catch (e) {
      console.log("error posting to fastapi server", e);
    }
  }
  async jslTokenize(body) {
    const url = this.base_url + "tokenizer";
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(body),
        headers: this.header,
      });
      const json = await response.json();
      if (!response.ok) {
        console.log("error posting to fastapi server");
      }
      if (response.ok) {
        console.log("backend successfully recieved ID");
        return json;
      }
    } catch (e) {
      console.log("error posting to fastapi server", e);
    }
  }
}

export default FastAPIHandler;
