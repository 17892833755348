import React, { useState, useEffect } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";

// Own components
import AnnotationManualCard from "./components/AnnotationManualCard";
import AnnotationKICard from "./components/AnnotationKICard";
import ObfscationCard from "./components/ObfuscationCard";
import ShowCard from "./components/ShowCard";
import Popup from "examples/Popup";

// @mui material components
import Grid from "@mui/material/Grid";

// Handler
import ExpressHandler from "handler/expressHandler";
import ExpressKeycloakHandler from "handler/expressKeycloakHandler";
import FastAPIHandler from "handler/fastapiHandler";
import LlmBackendHandler from "handler/llmBackendHandler";
import RbBackendHandler from "handler/rbBackendHandler";
import TokenizerBackendHandler from "handler/tokenizerBackendHandler";


function DeidProzess({
  title,
  fallnummer,
  inputText,
  editedText,
  nextData,
  prevData,
  chooseData,
  overviewArray,
  setOverviewArray,
  importedDataNumber,
  setImportedDataNumber,
  importedDataId,
  setDeidentificationVisibility,
  temporarySaving,
  setTemporarySaving,
  setContinueStudyDisabled,
  openToastSuccess
}) {
  const [inputTxtId, setInputTxtId] = useState("");
  const [tokens, setTokens] = useState([]);
  const [taggedTokensManual, setTaggedTokensManual] = useState([]);
  const [taggedTokensModel1, setTaggedTokensModel1] = useState([]);
  const [taggedTokensModel2, setTaggedTokensModel2] = useState([]);
  const [obfuscationTextModel1, setObfuscationTextModel1] = useState("");
  const [obfuscationTextModel2, setObfuscationTextModel2] = useState("");
  const [thumbsUpDownObfuscation, setThumbsUpDownObfuscation] = useState([[false, false], [false, false]]);
  const [loading, setLoading] = useState(true);
  const [buttonsVisibility, setButtonsVisibility] = useState([[false, false, false],[false, false, false]]);
  const [headerbuttonsVisibility, setHeaderButtonsVisibility] = useState(false);
  const [popupActiveStudieBeenden, setPopupActiveStudieBeenden] = useState(false);
  const [toastError, setToastError] = useState(false);
  const [toastWarning, setToastWarning] = useState(false);
  const [toastTitle, setToastTitle] = useState("Textfeld leer");
  const [toastContent, setToastContent] = useState("Textfeld hat keinen Inhalt");

  const expressHandler = new ExpressHandler();
  const expressKeycloakHandler = new ExpressKeycloakHandler();
  const fastapiHandler = new FastAPIHandler();
  const llmBackendHandler = new LlmBackendHandler();
  const rbBackendHandler = new RbBackendHandler();
  const tokenizerBackendHandler = new TokenizerBackendHandler();


  // Toast Objects + Logic
  const openToastWarning = (titel, content) => {
    setToastTitle(titel);
    setToastContent(content);
    setToastWarning(true);
  };
  const closeToastWarning = () => setToastWarning(false);
  const openToastError = (titel, content) => {
    setToastTitle(titel);
    setToastContent(content);
    setToastError(true);
  };
  const closeToastError = () => setToastError(false);

  const renderError = (
    <MDSnackbar
      color="error"
      icon="warning"
      title={toastTitle}
      content={toastContent}
      dateTime=""
      open={toastError}
      onClose={closeToastError}
      close={closeToastError}
      bgWhite
    />
  );
  const renderWarning = (
    <MDSnackbar
      color="warning"
      icon="notifications"
      title={toastTitle}
      content={toastContent}
      dateTime=""
      open={toastWarning}
      onClose={closeToastWarning}
      close={closeToastWarning}
      bgWhite
    />
  );

  const openPopup = async() => {
    setPopupActiveStudieBeenden(true);
  }

  const closePopup = () => {
    setPopupActiveStudieBeenden(false);
  }

  const onPageLoad = async (inTxt = "", num=0) => {
    if (inputText !== "" && temporarySaving.Tokens[num].length === 0) {
      setHeaderButtonsVisibility(false);
      const userId = expressKeycloakHandler.getCookie("user_id");
      const department = expressKeycloakHandler.getCookie("department");

      let body_01 = {
        txt: inputText,
        userId: userId,
        department: department,
      };
      // This is just for going to next or previous data. Without it there are timing / state issues in the useState variable inputText
      if (inTxt !== "") {
        body_01.txt = inTxt;
      }

      // Tokenize original text
      const tokens = await tokenizerBackendHandler.tokenize(inputText);

      // console.log(tokenized);

      // // Save original text
      // const response_savedTxtInput = await expressHandler.createTxt(body_01);
      // const savedTxtInputIdBody = { mongodb_txtinput_id: response_savedTxtInput._id };

      // // Tokenize original text
      // // const tokens = inputText.match(/[\w]+|[.,!?:]/g);
      // const responseTokenizedText = await fastapiHandler.jslTokenize(savedTxtInputIdBody);

      // // Check if backen (JSL) response is not undefined
      // if(responseTokenizedText == undefined) {
      //   const response_express_deleteTxtInput = await expressHandler.deleteTxtInput(response_savedTxtInput._id);
      //   return null;
      // }

      // setInputTxtId(response_savedTxtInput._id);

      // // Extract the tokens out of the JSL response
      // const tokens = responseTokenizedText.tokens;
      // let words = [];
      // tokens.forEach((token) => {
      //   words.push(token.token);
      // });
      // setTokens(words);
      setTokens(tokens);

      // Build the base tagged list
      // const taggedList = Array(words.length).fill("Original");
      const taggedList = Array(tokens.length).fill("Original");
      setTaggedTokensManual(taggedList);
      setTaggedTokensModel1(taggedList);
      setTaggedTokensModel2(taggedList);

      // Update teh current saving
      const newTemp = await expressHandler.getCurrentStudy(userId);
      newTemp[0].Tokens[num] = tokens;
      // newTemp[0].Tokens[num] = words;
      newTemp[0].TaggedManual[num] = taggedList;
      const updateBody = {
        Tokens: newTemp[0].Tokens,
        TaggedManual: newTemp[0].TaggedManual
      }
      const currStudy = await expressHandler.updateCurrentStudy(newTemp[0]._id, updateBody);
      currStudy.Tokens = newTemp[0].Tokens;
      currStudy.TaggedManual = newTemp[0].TaggedManual;
      setTemporarySaving(currStudy);

      setLoading(false);
      // Set all buttons visible
      setButtonsVisibility([[false, false, false],[true, true, false]]);
      setHeaderButtonsVisibility(true);
    } else if (inputText !== "" && temporarySaving.Tokens[num].length !== 0) {
      setTokens(temporarySaving.Tokens[num]);
      setTaggedTokensManual(temporarySaving.TaggedManual[num]);
      setTaggedTokensModel1(temporarySaving.TaggedModel1[num]);
      setObfuscationTextModel1(temporarySaving.ObfTextModel1[num]);
      setTaggedTokensModel2(temporarySaving.TaggedModel2[num]);
      setObfuscationTextModel2(temporarySaving.ObfTextModel2[num]);
      setThumbsUpDownObfuscation(temporarySaving.humanFeedbackObf[num]);
      setLoading(false);
      setButtonsVisibility([[false, false, false],[temporarySaving.ObfTextModel1[num] === "", temporarySaving.ObfTextModel2[num] === "", false]]);
      setHeaderButtonsVisibility(true);
    } else {
      console.log("Input text is empty");
    }
  };
  useEffect(() => {
    onPageLoad();
  }, []);

  // Deidentification Methods
  const startDeidentificationModel1 = async () => {
    try {
      // Disable Buttons
      setHeaderButtonsVisibility(false);
      const buttonsVisTemp = [...buttonsVisibility];
      buttonsVisTemp[0][0] = false;
      buttonsVisTemp[1][0] = false;
      setButtonsVisibility(buttonsVisTemp);

      // Rule based Method
      const body = {
        sentence: inputText,
        tokens: tokens,
      }
      const responseRbBackend = await rbBackendHandler.rbNer(body);

      const taggedList = responseRbBackend.annotation;
      responseRbBackend.annotation.forEach((entity, index) => {
        if (entity !== "o") {
          taggedList[index] = "<"+entity+">";
        }
      })

      // Update teh current saving
      const newTemp = { ...temporarySaving };
      newTemp.TaggedModel1[importedDataNumber] = taggedList;
      newTemp.ObfTextModel1[importedDataNumber] = responseRbBackend.obfuscated_sentence;
      const updateBody = {
        TaggedModel1: newTemp.TaggedModel1,
        ObfTextModel1: newTemp.ObfTextModel1,
      }
      const currStudy = await expressHandler.updateCurrentStudy(temporarySaving._id, updateBody);
      setTemporarySaving(newTemp);

      setTaggedTokensModel1(taggedList);
      setObfuscationTextModel1(responseRbBackend.obfuscated_sentence);
      setHeaderButtonsVisibility(true);

    } catch (error) {
      // Enable Button
      setHeaderButtonsVisibility(true);
      const buttonsVisTemp = [...buttonsVisibility];
      buttonsVisTemp[0][0] = true;
      setButtonsVisibility(buttonsVisTemp);
      openToastError("Backend nicht erreichbar", "Backend für Model 1 ist nicht erreichbar");
    }
  };

  const startDeidentificationModel2 = async () => {
    try {
      // Disable Buttons
      setHeaderButtonsVisibility(false);
      const buttonsVisTemp = [...buttonsVisibility];
      buttonsVisTemp[0][1] = false;
      buttonsVisTemp[1][1] = false;
      setButtonsVisibility(buttonsVisTemp);

      // Fetch data from model 2
      const responseLlmBackend = await llmBackendHandler.deidLlmBackend(inputText, "adrienbrault/nous-hermes2pro-llama3-8b:q8_0", "b0", "deidentification");

      // const responseLlmBackend = ["bla bli blub", { "age": ["15"], "date": ["seit April 22", "PostCovid", "22. September"]}]

      const taggedList = [...taggedTokensModel2];

      responseLlmBackend.name.forEach((item) => {
        tokens.forEach((tok, index) => {
          const words = item.split(/\s+/);
          words.forEach((word, wordIndex) => {
            if (word === tok) {
              taggedList[index] = "<NAME>";
            }
          })
        })
      })
      responseLlmBackend.age.forEach((item) => {
        tokens.forEach((tok, index) => {
          const words = item.split(/\s+/);
          words.forEach((word, wordIndex) => {
            if (word === tok) {
              taggedList[index] = "<AGE>";
            }
          })
        })
      })
      responseLlmBackend.location.forEach((item) => {
        tokens.forEach((tok, index) => {
          const words = item.split(/\s+/);
          words.forEach((word, wordIndex) => {
            if (word === tok) {
              taggedList[index] = "<LOCATION>";
            }
          })
        })
      })
      responseLlmBackend.date.forEach((item) => {
        tokens.forEach((tok, index) => {
          const words = item.split(/\s+/);
          words.forEach((word, wordIndex) => {
            if (word === tok) {
              taggedList[index] = "<DATE>";
            }
          })
        })
      })

      const responseAnonymLlmBackend = await llmBackendHandler.anonymLlmBackend(inputText, responseLlmBackend);

      // Update teh current saving
      const newTemp = { ...temporarySaving };
      newTemp.TaggedModel2[importedDataNumber] = taggedList;
      newTemp.ObfTextModel2[importedDataNumber] = responseAnonymLlmBackend;
      const updateBody = {
        TaggedModel2: newTemp.TaggedModel2,
        ObfTextModel2: newTemp.ObfTextModel2,
      }
      const currStudy = await expressHandler.updateCurrentStudy(temporarySaving._id, updateBody);
      setTemporarySaving(newTemp);

      setObfuscationTextModel2(responseAnonymLlmBackend);
      setTaggedTokensModel2(taggedList);
      setHeaderButtonsVisibility(true);

    } catch (error) {
      // Enable Buttons
      setHeaderButtonsVisibility(true);
      const buttonsVisTemp = [...buttonsVisibility];
      buttonsVisTemp[0][1] = true;
      setButtonsVisibility(buttonsVisTemp);
      console.log(error);
      openToastError("Backend nicht erreichbar", "Backend Model 2 nicht erreichbar");
    }
  };

  const buttonPreviouse = async () => {
    resetAll();
    const [inTxt, num] = prevData();
    await onPageLoad(inTxt, num);
  }
  const buttonNext = async () => {
    resetAll();
    const [inTxt, num] = nextData();
    await onPageLoad(inTxt, num);
  }

  const buttonChooseCase = async (num) => {
    resetAll();
    const [inTxt, number] = chooseData(num);
    await onPageLoad(inTxt, number);
  }

  const buttonFinishCase = async () => {

    //check little box for that case
    const checkBoxes = [...overviewArray];
    checkBoxes[importedDataNumber] = true;
    setOverviewArray(checkBoxes);
    // jump to next case
    await buttonNext();

    // const filled = checkFilled();
    // if (inputText!=="" && filled) {
    //   // check little box for that case
    //   const checkBoxes = [...overviewArray];
    //   checkBoxes[importedDataNumber] = true;
    //   setOverviewArray(checkBoxes);
    //   // jump to next case
    //   await buttonNext();

    // } else {
    //   // TODO Why could not be saved?
    //   return null
    // }
  }

  const buttonFinishStudy = async () => {
    // const filled = checkFilled();
    setHeaderButtonsVisibility(false);
    setImportedDataNumber(0);
    // Save data in mongoDB
    const save_body = {
      "importedDataIds": temporarySaving.impDataId,
      "fallnummern": temporarySaving.impDataFallnummer,
      "OriginalTexte": temporarySaving.impDataOriginalText,
      "ExpertenTexte": temporarySaving.impDataEditedText,
      "Tokens": temporarySaving.Tokens,
      "TaggedTokensManual": temporarySaving.TaggedManual,
      "TaggedTokensModel1": temporarySaving.TaggedModel1,
      "TaggedTokensModel2": temporarySaving.TaggedModel2,
      "ObfuscationTextModel1": temporarySaving.ObfTextModel1,
      "ObfuscationTextModel2": temporarySaving.ObfTextModel2,
      "humanFeedbackObfuscation": temporarySaving.humanFeedbackObf,
      "userId": temporarySaving.userId,
      "department": temporarySaving.department,
    }

    const response_express_processedData = await expressHandler.createResearchData(save_body);
    const resp_deleteCurrStudy = await expressHandler.deleteCurrentStudy(temporarySaving._id);
    resetAll();
    setDeidentificationVisibility(false);
    setContinueStudyDisabled(true);
    openToastSuccess("Studie gespeichert","");
  }

  const saveStudy = () => {
    setDeidentificationVisibility(false);
    setContinueStudyDisabled(false);
    openToastSuccess("Studie gespeichert","");
    setImportedDataNumber(0);
  }

  const buttonThumbsUpDownObfuscation = async (modelNum, thumbNum) => {
    const thumbsArray = [...thumbsUpDownObfuscation];
    if (thumbsArray[modelNum][thumbNum]) {
      thumbsArray[modelNum][thumbNum] = false;
    } else {
      thumbsArray[modelNum][thumbNum] = true;
      if (thumbNum===0) {
        thumbsArray[modelNum][1] = false;
      } else {
        thumbsArray[modelNum][0] = false;
      }
    }
    console.log(thumbsArray);
    // Update teh current saving
    const newTemp = { ...temporarySaving };
    newTemp.humanFeedbackObf[importedDataNumber] = thumbsArray;
    const updateBody = {
      humanFeedbackObf: newTemp.humanFeedbackObf,
    }
    const currStudy = await expressHandler.updateCurrentStudy(temporarySaving._id, updateBody);
    setTemporarySaving(newTemp);

    setThumbsUpDownObfuscation(thumbsArray);
  }

  const checkFilled = () => {
    if (loading) {
      openToastWarning("Automatischer Anonymisierungsprozess nicht fertig", "Anonymisierung läuft nocht");
      return false;
    }

    if (taggedTokensModel1.length === 0) {
      console.log("Deidentifikation Modell 1 wurde nicht ausgeführt");
      return false;
    }

    if (taggedTokensModel2.length === 0) {
      console.log("Deidentifikation Modell 2 wurde nicht ausgeführt");
      return false;
    }

    if (obfuscationTextModel1 === "") {
      console.log("Obfuszierung Modell 1 wurde nicht ausgeführt");
      return false;
    }

    if (obfuscationTextModel2 === "") {
      console.log("Obfuszierung Modell 2 wurde nicht ausgeführt");
      return false;
    }
    return true;
  }
  const resetAll = () => {
    setTokens([]);
    setTaggedTokensManual([]);
    setTaggedTokensModel1([]);
    setTaggedTokensModel2([]);
    setObfuscationTextModel1("");
    setObfuscationTextModel2("");
    setThumbsUpDownObfuscation([[false, false], [false, false]]);
    setLoading(true);
    setButtonsVisibility([[true, true, true],[true, true, true]]);
  }
  return (
    <MDBox>
      {renderError}
      {renderWarning}
      {popupActiveStudieBeenden &&
        <Popup
          title="Studie Beenden"
          content="Sind sie sicher, dass sie die Studie beenden möchten?"
          buttonLableLeft="Studie beenden"
          buttonLableRight="Fortfahren"
          buttonFunctionLeft={buttonFinishStudy}
          buttonFunctionRight={closePopup}
        />
      }
      <MDBox display="flex" justifyContent="space-between" style={{ minHeight:"50px", maxHeight:"50px" }}>
        <MDBox display="flex" gap={1}>
          <MDButton variant="gradient" color="dark" onClick={() => buttonPreviouse()} style={{ fontSize:"20px" }} disabled={!headerbuttonsVisibility}>
            &lt;
          </MDButton>
          <MDButton variant="gradient" color="dark" onClick={() => buttonNext()} style={{ fontSize:"20px" }} disabled={!headerbuttonsVisibility}>
            &gt;
          </MDButton>
          <MDBox display="flex" flexDirection="column" ml={2} style={{ minWidth:"350px", maxWidth:"350px" }}>
            <MDTypography variant="h6">{title}</MDTypography>
            <MDTypography variant="subtitle2">Fallnummer: {fallnummer}</MDTypography>
          </MDBox>
          <MDBox mx={4} pr={2} display="flex" flexWrap="wrap" gap="5px" style={{ maxWidth:"400px", minHeight: "58px", maxHeight:"100px", overflowY:"scroll", scrollbarWidth: "none", msOverflowStyle: "none" }}>
            {overviewArray.map((item, index) => (
              <MDBox
                onClick={(headerbuttonsVisibility) ? () => buttonChooseCase(index) : null}
                style={{
                  backgroundColor: index === importedDataNumber ? "#1A73E8" : item ? "green" : "transparent",
                  minWidth:"15px",
                  minHeight:"15px",
                  maxHeight:"15px",
                  border:"1px solid #9db1bd",
                  cursor: (headerbuttonsVisibility) ? "pointer" : "default"
                }}
              />
            ))}
          </MDBox>
        </MDBox>
        <MDBox display="flex" gap={4}>
          <MDButton variant="gradient" color="dark" onClick={() => saveStudy()} disabled={!headerbuttonsVisibility}>
              Studie Speichern
          </MDButton>
          {importedDataNumber !== temporarySaving.impDataId.length-1 ? (
            <MDButton variant="gradient" color="dark" onClick={() => buttonFinishCase()} disabled={!headerbuttonsVisibility}>
              Fall abschließen
            </MDButton>
          ) : (
            <MDButton variant="gradient" color="dark" onClick={() => openPopup()} disabled={!headerbuttonsVisibility}>
              Studie Beenden
            </MDButton>
          )}
        </MDBox>
      </MDBox>
      <MDBox mt={3}>
        <MDTypography variant="h5" style={{ marginBottom:"8px" }}>Fallbeschreibung</MDTypography>
        <Grid container spacing={5} rowSpacing={5}>
          <ShowCard
            text={inputText}
            header="Originaler Text"
          />
          <AnnotationManualCard
            tokens={tokens}
            setTokens={setTokens}
            loading={loading}
            taggedTokensManual={taggedTokensManual}
            setTaggedTokensManual={setTaggedTokensManual}
            temporarySaving={temporarySaving}
            setTemporarySaving={setTemporarySaving}
            importedDataNumber={importedDataNumber}
          />
          <ShowCard
            text={editedText}
            header="Experten Ergebnis"
          />
          <ObfscationCard
            text={obfuscationTextModel1}
            header="Anonymisierung Modell 1"
            buttonVisibility={buttonsVisibility[1][0]}
            buttonThumbsUpDown={buttonThumbsUpDownObfuscation}
            thumbsUpDown={thumbsUpDownObfuscation}
            startObfuscation={startDeidentificationModel1}
            modelNumber={0}
          />
          <ObfscationCard
            text={obfuscationTextModel2}
            header="Anonymisierung Modell 2"
            buttonVisibility={buttonsVisibility[1][1]}
            buttonThumbsUpDown={buttonThumbsUpDownObfuscation}
            thumbsUpDown={thumbsUpDownObfuscation}
            startObfuscation={startDeidentificationModel2}
            modelNumber={1}
          />
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default DeidProzess;
