import React, { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

function ShowCard({
  text,
  header
}) {
  return (
    <Grid item xs={12} md={6} lg={4}>
      <MDBox>
        <Card>
          <MDBox
            display="flex"
            flexDirection="column"
            rowGap={1}
            py={1}
            px={2}
            maxHeight="325px"
            style={{ minHeight: "325px" }}
          >
            <MDBox display="flex" justifyContent="space-between">
              <MDTypography variant="h5">{header}</MDTypography>
            </MDBox>
            <MDBox
              borderRadius="lg"
              padding="10px 40px 0px 25px"
              style={{
                marginBottom:10,
                marginTop:10,
                height:1000,
                maxHeight: "100%",
                overflowY: "auto",
                border: "1px solid #dee2e6"
              }}
            >
              {text && (
                <MDTypography variant="body2">{text}</MDTypography>
              )}
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </Grid>
  );
}

// Setting default values
ShowCard.defaultProps = {
  text: "",
  header: "",
};

// Typechecking props 
ShowCard.propTypes = {
  text: PropTypes.string,
  header: PropTypes.string,
};

export default ShowCard;
