import React, { useState, useEffect } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Own components
import OrginaltextCard from "./components/OrginaltextCard";
import KITextCard from "./components/KITextCard";
import ResultatCard from "./components/ResultatCard";

// @mui material components
import Grid from "@mui/material/Grid";


// Handler
import ExpressHandler from "handler/expressHandler";
import ExpressKeycloakHandler from "handler/expressKeycloakHandler";
import LlmBackendHandler from "handler/llmBackendHandler";
import { Icon, Typography } from "@mui/material";


function DeidProzess({
  title,
  fallnummer,
  inputText_01,
  inputText_02,
  inputText_03,
  inputText_04,
  inputText_05,
  inputText_06,
  setInputText_01,
  setInputText_02,
  setInputText_03,
  setInputText_04,
  setInputText_05,
  setInputText_06,
  setDeidProzessToggle,
  deidProzessToggle,
  nextData,
  prevData,
  importedDataId,
  chooseRandomDeidentificationMethod,
  setTableData,
}) {
  const [deidInference_01, setDeidInference_01] = useState([]);
  const [deidInference_02, setDeidInference_02] = useState([]);
  const [deidInference_03, setDeidInference_03] = useState([]);
  const [deidInference_04, setDeidInference_04] = useState([]);
  const [deidInference_05, setDeidInference_05] = useState([]);
  const [deidInference_06, setDeidInference_06] = useState([]);
  const [obfuscatedText_01, setObfuscatedText_01] = useState("");
  const [obfuscatedText_02, setObfuscatedText_02] = useState("");
  const [obfuscatedText_03, setObfuscatedText_03] = useState("");
  const [obfuscatedText_04, setObfuscatedText_04] = useState("");
  const [obfuscatedText_05, setObfuscatedText_05] = useState("");
  const [obfuscatedText_06, setObfuscatedText_06] = useState("");
  const [inputPostprocessingText_01, setInputPostprocessingText_01] = useState("");
  const [inputPostprocessingText_02, setInputPostprocessingText_02] = useState("");
  const [inputPostprocessingText_03, setInputPostprocessingText_03] = useState("");
  const [inputPostprocessingText_04, setInputPostprocessingText_04] = useState("");
  const [inputPostprocessingText_05, setInputPostprocessingText_05] = useState("");
  const [inputPostprocessingText_06, setInputPostprocessingText_06] = useState("");
  const [loading_01, setLoading_01] = useState(true);
  const [loading_02, setLoading_02] = useState(true);
  const [loading_03, setLoading_03] = useState(true);
  const [loading_04, setLoading_04] = useState(true);
  const [loading_05, setLoading_05] = useState(true);
  const [loading_06, setLoading_06] = useState(true);
  const [time, setTime] = useState({ minutes: 0, seconds: 0 });
  const [jslId, setJslId] = useState("");

  const expressHandler = new ExpressHandler();
  const expressKeycloakHandler = new ExpressKeycloakHandler();
  const llmBackendHandler = new LlmBackendHandler();
  const handleTextInputChange = (text, e) => {
    switch (text) {
      case 1:
        setInputText_01(e.target.value);
        break;
      case 2:
        setInputText_02(e.target.value);
        break;
      case 3:
        setInputText_03(e.target.value);
        break;
      case 4:
        setInputText_04(e.target.value);
        break;
      case 5:
        setInputText_05(e.target.value);
        break;
      case 6:
        setInputText_06(e.target.value);
        break;
      default:
        break;
    }
  };
  const handleTextResultatChange = (text, e) => {
    switch (text) {
      case 1:
        setInputPostprocessingText_01(e.target.value);
        break;
      case 2:
        setInputPostprocessingText_02(e.target.value);
        break;
      case 3:
        setInputPostprocessingText_03(e.target.value);
        break;
      case 4:
        setInputPostprocessingText_04(e.target.value);
        break;
      case 5:
        setInputPostprocessingText_05(e.target.value);
        break;
      case 6:
        setInputPostprocessingText_06(e.target.value);
        break;
      default:
        break;
    }
  };
  const deidentify = async (inputText, setObfuscatedText, setInputPostprocessingText, setDeidInference) => {
    if (inputText !== "") {
      setObfuscatedText("");
      
      // Fetch data from model 2
      const responseLlmBackend = await llmBackendHandler.deidLlmBackend(inputText, "adrienbrault/nous-hermes2pro-llama3-8b:q8_0", "b0", "deidentification");

      const responseAnonymLlmBackend = await llmBackendHandler.anonymLlmBackend(inputText, responseLlmBackend);
      const resp_incrementRuns = await expressKeycloakHandler.incrementToken();

      setObfuscatedText(responseAnonymLlmBackend);
      setInputPostprocessingText(responseAnonymLlmBackend);
      setDeidInference(responseLlmBackend);
    } else {
      console.log("Input text is empty");
    }
  }
  const startDeidentifikationKi = async () => {
    await deidentify(inputText_01, setObfuscatedText_01, setInputPostprocessingText_01, setDeidInference_01);
    setLoading_01(false);
    await deidentify(inputText_02, setObfuscatedText_02, setInputPostprocessingText_02, setDeidInference_02);
    setLoading_02(false);
    await deidentify(inputText_03, setObfuscatedText_03, setInputPostprocessingText_03, setDeidInference_03);
    setLoading_03(false);
    await deidentify(inputText_04, setObfuscatedText_04, setInputPostprocessingText_04, setDeidInference_04);
    setLoading_04(false);
    await deidentify(inputText_05, setObfuscatedText_05, setInputPostprocessingText_05, setDeidInference_05);
    setLoading_05(false);
    await deidentify(inputText_06, setObfuscatedText_06, setInputPostprocessingText_06, setDeidInference_06);
    setLoading_06(false);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(prevTime => {
        const newSeconds = prevTime.seconds + 1;
        const newMinutes = prevTime.minutes + Math.floor(newSeconds / 60);
        return {
          minutes: newMinutes,
          seconds: newSeconds % 60
        };
      });
    }, 1000);
    startDeidentifikationKi();
    return () => clearInterval(interval);
  }, []);
  
  const buttonFinishReturn = async () => {
    if (!loading_01 && !loading_02 && !loading_03 && !loading_04 && !loading_05 && !loading_06) {
      chooseRandomDeidentificationMethod();

      const userId = expressKeycloakHandler.getCookie("user_id");
      const department = expressKeycloakHandler.getCookie("department");

      const bodyProcessData = {
        importedDataId: importedDataId,
        input: [inputText_01, inputText_02, inputText_03, inputText_04, inputText_05, inputText_06],
        aiInference: [deidInference_01, deidInference_02, deidInference_03, deidInference_04, deidInference_05, deidInference_06],
        postprocessedTxt: [obfuscatedText_01, obfuscatedText_02, obfuscatedText_03, obfuscatedText_04, obfuscatedText_05, obfuscatedText_06],
        caseTime: [time],
        userId: userId,
        department: department,
        aiUsed: true,
      }
      const [responseExpressPD, responseExpressImportData] = await Promise.all([
        expressHandler.createProductionData(bodyProcessData),
        expressHandler.updateImportData(importedDataId, { deidentifiziert: true })
      ]);

      setLoading_01(true);
      setLoading_02(true);
      setLoading_03(true);
      setLoading_04(true);
      setLoading_05(true);
      setLoading_06(true);
      setTime({ minutes: 0, seconds: 0 });

      // Update of Table
      const responseExpressImpData = await expressHandler.getImportData();
      setTableData({ type: "LOAD_DATA_FROM_MONGODB", payload: responseExpressImpData });

      setDeidProzessToggle(false);
    }
  }
  const buttonReturnToFallueberblick = () => {
    chooseRandomDeidentificationMethod();
    setDeidProzessToggle(false);
    setInputText_01("");
    setInputText_02("");
    setInputText_03("");
    setInputText_04("");
    setInputText_05("");
    setInputText_06("");
  }
  return (
    <MDBox>
      <MDBox display="flex" justifyContent="space-between">
        <MDBox display="flex" gap={1}>
          <MDButton variant="gradient" color="dark" onClick={() => buttonReturnToFallueberblick()} style={{ fontSize:"20px" }}>
            <Icon>backup_table</Icon>
          </MDButton>
          {/* <MDButton variant="gradient" color="dark" onClick={() => buttonPreviouse()} style={{ fontSize:"20px" }}>
            &lt;
          </MDButton>
          <MDButton variant="gradient" color="dark" onClick={() => buttonNext()} style={{ fontSize:"20px" }}>
            &gt;
          </MDButton> */}
          <MDBox display="flex" flexDirection="column" ml={2}>
            <MDTypography variant="h6">{title}</MDTypography>
            <MDTypography variant="subtitle2">Fallnummer: {fallnummer}</MDTypography>
          </MDBox>
          <MDBox ml={5}>
            <MDTypography>Timer: {time.minutes < 10 ? '0' : ''}{time.minutes}:{time.seconds < 10 ? '0' : ''}{time.seconds}</MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" gap={3}>
          {(!loading_01 && !loading_02 && !loading_03 && !loading_04 && !loading_05 && !loading_06) && 
            <MDButton variant="gradient" color="dark" onClick={() => buttonFinishReturn()}>
              Abschließen und zur Übersicht
            </MDButton>
          }
          {/* {(!loading_01 && !loading_02 && !loading_03 && !loading_04 && !loading_05 && !loading_06) &&
            <MDButton variant="gradient" color="dark" onClick={() => buttonFinishReturn()}>
              Abschließen Nächster Fall
            </MDButton>
          } */}
        </MDBox>
      </MDBox>
      <MDBox mt={5}>
        <MDTypography variant="h5" style={{ marginBottom:"8px" }}>Fallbeschreibung</MDTypography>
        <Grid container spacing={5} rowSpacing={5}>
          <OrginaltextCard
          inputValue={inputText_01}
          handleTextInputChange={(e) => handleTextInputChange(1, e)}
          txtInputDisable={true} />
          <KITextCard
            text={obfuscatedText_01}
            header="Ergebnis der KI"
            loading={loading_01} />
          <ResultatCard
            text={inputPostprocessingText_01}
            header="Nachbearbeitung"
            handleTextInputChange={(e) => handleTextResultatChange(1, e)}
            loading={loading_01} />
        </Grid>
      </MDBox>
      <MDBox mt={5}>
        <MDTypography variant="h5" style={{ marginBottom:"8px" }}>Was ist in ihrem Fall nicht gut verlaufen?</MDTypography>
        <Grid container spacing={5} rowSpacing={5}>
          <OrginaltextCard
            inputValue={inputText_02}
            handleTextInputChange={(e) => handleTextInputChange(2, e)}
            txtInputDisable={true} />
          <KITextCard
            text={obfuscatedText_02}
            header="Ergebnis der KI"
            loading={loading_02} />
          <ResultatCard
            text={inputPostprocessingText_02}
            header="Nachbearbeitung"
            handleTextInputChange={(e) => handleTextResultatChange(2, e)}
            loading={loading_02} />
        </Grid>
      </MDBox>
      <MDBox mt={5}>
        <MDTypography variant="h5" style={{ marginBottom:"8px" }}>Was war in Ihrem Fall ggf. besonders gut/positiv?</MDTypography>
        <Grid container spacing={5} rowSpacing={5}>
          <OrginaltextCard
            inputValue={inputText_03}
            handleTextInputChange={(e) => handleTextInputChange(3, e)}
            txtInputDisable={true} />
          <KITextCard
            text={obfuscatedText_03}
            header="Ergebnis der KI"
            loading={loading_03} />
          <ResultatCard
            text={inputPostprocessingText_03}
            header="Nachbearbeitung"
            handleTextInputChange={(e) => handleTextResultatChange(3, e)}
            loading={loading_03} />
        </Grid>
      </MDBox>
      <MDBox mt={5}>
        <MDTypography variant="h5" style={{ marginBottom:"8px" }}>Was waren beitragende Faktoren?</MDTypography>
        <Grid container spacing={5} rowSpacing={5}>
          <OrginaltextCard
            inputValue={inputText_04}
            handleTextInputChange={(e) => handleTextInputChange(4, e)}
            txtInputDisable={true} />
          <KITextCard
            text={obfuscatedText_04}
            header="Ergebnis der KI"
            loading={loading_04} />
          <ResultatCard
            text={inputPostprocessingText_04}
            header="Nachbearbeitung"
            handleTextInputChange={(e) => handleTextResultatChange(4, e)}
            loading={loading_04} />
        </Grid>
      </MDBox>
      <MDBox mt={5}>
        <MDTypography variant="h5" style={{ marginBottom:"8px" }}>Was könnte man aus Ihrem Fall lernen?</MDTypography>
        <Grid container spacing={5} rowSpacing={5}>
          <OrginaltextCard
            inputValue={inputText_05}
            handleTextInputChange={(e) => handleTextInputChange(5, e)}
            txtInputDisable={true} />
          <KITextCard
            text={obfuscatedText_05}
            header="Ergebnis der KI"
            loading={loading_05} />
          <ResultatCard
            text={inputPostprocessingText_05}
            header="Nachbearbeitung"
            handleTextInputChange={(e) => handleTextResultatChange(5, e)}
            loading={loading_05} />
        </Grid>
      </MDBox>
      <MDBox mt={5}>
        <MDTypography variant="h5" style={{ marginBottom:"8px" }}>Haben sie noch weitere Gedanken zu Ihrem Fall?</MDTypography>
        <Grid container spacing={5} rowSpacing={5}>
          <OrginaltextCard
            inputValue={inputText_06}
            handleTextInputChange={(e) => handleTextInputChange(6, e)}
            txtInputDisable={true} />
          <KITextCard
            text={obfuscatedText_06}
            header="Ergebnis der KI"
            loading={loading_06} />
          <ResultatCard
            text={inputPostprocessingText_06}
            header="Nachbearbeitung"
            handleTextInputChange={(e) => handleTextResultatChange(6, e)}
            loading={loading_06} />
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default DeidProzess;
