class TokenizerBackendHandler {
  // baseURL = "http://81.89.199.178:5004/api/backend/rulebased";
  // baseURL = "http://localhost:5004/api/backend/tokenizer";
  // baseURL = "https://xp-kc.covid-companion.de/api/backend/tokenizer";
  baseURL = "https://xp-kc.covid-companion.de/api/backend/llm";
  // baseURL = "http://81.89.199.166:5004/api/backend/llm";
  basicUsername = process.env.REACT_APP_BASIC_USERNAME;
  basicPassword = process.env.REACT_APP_BASIC_PASSWORD;
  /**
   * Makes a request to the express server route to tokenize a string.
   * @param {*} body Information of the text to be tokenized. Example: {"input_text": "Ich nehme 100% Wasser, täglich zu mir.", "step": "word_tokenizer"}
   * @returns Array with tokenized input
   */
  async tokenize(textToBeTokenized) {
    const credentials = `${this.basicUsername}:${this.basicPassword}`;
    const base64Credentials = btoa(credentials);

    const body = {
      input_text: textToBeTokenized, 
      step: "word_tokenizer"
    };

    const response = await fetch(this.baseURL + "/getresponse", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${base64Credentials}`
      },
    });
    const json = await response.json();
    if (!response.ok) {
      console.log("response backend tokenizer failed");
    }
    if (response.ok) {
      console.log("responce backend tokenizer successfull");
      return json;
    }
  };
}

export default TokenizerBackendHandler;