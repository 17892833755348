import getHeader from "./header";

const baseURL = process.env.REACT_APP_EXPRESS_API;

export async function anonymize(body) {
  try {
    const response = await fetch(`${baseURL}/api/backend/anonymize`, {
      method: "POST",
      headers: getHeader(),
      body: JSON.stringify(body),
    });

    const json = await response.json();
    if (!response.ok) {
      console.error("Failed anonymize:", json);
      throw new Error(json.message || "Error calling backend");
    }

    return json;
  } catch (error) {
    console.error("Error in anonymize", error);
    return [];
  }
};