import getHeader from "./header";

const baseURL = process.env.REACT_APP_EXPRESS_API;

export async function getCases() {
  try {
    const response = await fetch(`${this.baseURL}/api/mongo/cases`, {
      method: "GET",
      headers: getHeader(),
    });

    const json = await response.json();
    if (!response.ok) {
      console.error("Failed to fetch cases:", json);
      throw new Error(json.message || "Error fetching cases");
    }

    return json;
  } catch (error) {
    console.error("Error in getCases:", error);
    return [];
  }
};

export async function getUniqueCase(caseId) {
  try {
    const response = await fetch(`${this.baseURL}/api/mongo/cases/${caseId}`, {
      method: "GET",
      headers: getHeader(),
    });

    const json = await response.json();
    if (!response.ok) {
      console.error("Failed to fetch case:", json);
      throw new Error(json.message || "Error fetching case");
    }

    return json;
  } catch (error) {
    console.error("Error in getUniqueCase:", error);
    return [];
  }
};

export async function createCase(body) {
  try {
    const response = await fetch(`${baseURL}/api/mongo/cases`, {
      method: "POST",
      headers: getHeader(),
      body: JSON.stringify(body),
    });

    const json = await response.json();
    if (!response.ok) {
      console.error("Failed to create case:", json);
      throw new Error(json.message || "Error creating case");
    }

    return json;
  } catch (error) {
    console.error("Error in createCase:", error);
    return null;
  }
}

export async function deleteCase(caseId) {
  try {
    const response = await fetch(`${baseURL}/api/mongo/cases/${caseId}`, {
      method: "DELETE",
      headers: getHeader(),
    });

    if (!response.ok) {
      const json = await response.json();
      console.error("Failed to delete case:", json);
      throw new Error(json.message || "Error deleting case");
    }

    return { success: true };
  } catch (error) {
    console.error("Error in deleteCase:", error);
    return { success: false };
  }
}