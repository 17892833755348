import React from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Additional components (React Spinner)
import HashLoader from "react-spinners/HashLoader";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Own components
import TextLabeling from "examples/TextLabeling";

// Handler
// import ExpressHandler from "handler/expressHandler";

function ResultatCard({
  text,
  header,
  handleTextInputChange,
  loading,
}) {
  return (
    <Grid item xs={12} md={6} lg={4}>
      <MDBox>
        <Card>
          <MDBox
            display="flex"
            flexDirection="column"
            rowGap={2}
            py={1}
            px={2}
            maxHeight="325px"
            style={{ minHeight: "325px" }}
          >
            <MDBox display="flex" alignItems="center" justifyContent="space-between">
              <MDTypography variant="h5">{header}</MDTypography>
            </MDBox>
            {loading ? (
              <MDBox
                borderRadius="lg"
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  marginBottom:10,
                  marginTop:10,
                  height:1000,
                  maxHeight: "100%",
                  overflowY: "auto",
                  border: "1px solid #dee2e6"
                }}
              >
                <HashLoader
                  color="#dee2e6"
                  loading={loading}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </MDBox>
            ) : (
              <MDInput
                label="Text hier einfügen ..."
                multiline
                rows={11}
                value={text}
                onChange={(e) => handleTextInputChange(e)}
              />
            )}
          </MDBox>
        </Card>
      </MDBox>
    </Grid>
  );
}

// Setting default values
ResultatCard.defaultProps = {
  manualText: [],
  setManualText: () => {},
  aiTaggedEntityList: [],
  setAiTaggedEntityList: () => {},
  loading: false,
};

// Typechecking props 
ResultatCard.propTypes = {
  manualText: PropTypes.array,
  setManualText: PropTypes.func.isRequired,
  aiTaggedEntityList: PropTypes.array,
  setAiTaggedEntityList: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default ResultatCard;
