import { login, logout, checkRefreshToken, createUser } from "./functions/authentification";

export default class KeycloakService {


  async login(body, { loginUser }) {
    // expects body with credentials { username: "xxx", password: "xxx"}
    return await login(body, { loginUser });
  }

  async logout({ tokens }) {
    return await logout({ tokens });
  }

  async checkRefreshToken(tokens) {
    return await checkRefreshToken(tokens);
  }

  async createUser(body) {
    // expects body with userData
    // {
    //   username: "username",
    //   firstName: "firstName",
    //   lastName: "lastName",
    //   email: "email",
    //   emailVerified: true,
    //   enabled: true,
    //   credentials: [
    //     {
    //       value: "password",
    //       temporary: false
    //     }
    //   ],
    //   // attributes: {
    //   //   fhir_id: [
    //   //     "urn:uuid:" + uuid
    //   //   ]
    //   // }
    // }
    return await createUser(body);
  }
}