import React, { useState, useEffect } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";

// Own components
import OrginaltextCard from "./components/OrginaltextCard";
import ResultatCard from "./components/ResultatCard";

// @mui material components
import Grid from "@mui/material/Grid";

// Handler
import ExpressHandler from "handler/expressHandler";
import ExpressKeycloakHandler from "handler/expressKeycloakHandler";
import FastAPIHandler from "handler/fastapiHandler";
import { Icon } from "@mui/material";
import { check } from "prettier";


function DeidProzessManuell({
  title,
  fallnummer,
  inputText_01,
  inputText_02,
  inputText_03,
  inputText_04,
  inputText_05,
  inputText_06,
  setInputText_01,
  setInputText_02,
  setInputText_03,
  setInputText_04,
  setInputText_05,
  setInputText_06,
  setDeidProzessToggle,
  deidProzessToggle,
  nextData,
  prevData,
  importedDataId,
  chooseRandomDeidentificationMethod,
  setTableData,
}) {
  const [obfuscatedText_01, setObfuscatedText_01] = useState("");
  const [obfuscatedText_02, setObfuscatedText_02] = useState("");
  const [obfuscatedText_03, setObfuscatedText_03] = useState("");
  const [obfuscatedText_04, setObfuscatedText_04] = useState("");
  const [obfuscatedText_05, setObfuscatedText_05] = useState("");
  const [obfuscatedText_06, setObfuscatedText_06] = useState("");
  const [time, setTime] = useState({ minutes: 0, seconds: 0 });
  const [toastWarning, setToastWarning] = useState(false);
  const [toastTitle, setToastTitle] = useState("Textfeld leer");
  const [toastContent, setToastContent] = useState("Textfeld hat keinen Inhalt");

  const expressHandler = new ExpressHandler();
  const expressKeycloakHandler = new ExpressKeycloakHandler();

  // Toast Objects + Logic
  const openToastWarning = (titel, content) => {
    setToastTitle(titel);
    setToastContent(content);
    setToastWarning(true);
  };
  const closeToastWarning = () => setToastWarning(false);
  const renderWarning = (
    <MDSnackbar
      color="warning"
      icon="notifications"
      title={toastTitle}
      content={toastContent}
      dateTime=""
      open={toastWarning}
      onClose={closeToastWarning}
      close={closeToastWarning}
      bgWhite
    />
  );

  const handleTextInputChange = (text, e) => {
    switch (text) {
      case 1:
        setInputText_01(e.target.value);
        break;
      case 2:
        setInputText_02(e.target.value);
        break;
      case 3:
        setInputText_03(e.target.value);
        break;
      case 4:
        setInputText_04(e.target.value);
        break;
      case 5:
        setInputText_05(e.target.value);
        break;
      case 6:
        setInputText_06(e.target.value);
        break;
      default:
        break;
    }
  };
  const handleTextResultatChange = (text, e) => {
    switch (text) {
      case 1:
        setObfuscatedText_01(e.target.value);
        break;
      case 2:
        setObfuscatedText_02(e.target.value);
        break;
      case 3:
        setObfuscatedText_03(e.target.value);
        break;
      case 4:
        setObfuscatedText_04(e.target.value);
        break;
      case 5:
        setObfuscatedText_05(e.target.value);
        break;
      case 6:
        setObfuscatedText_06(e.target.value);
        break;
      default:
        break;
    }
  };
  const onPageLoad = () => {
    setObfuscatedText_01(inputText_01);
    setObfuscatedText_02(inputText_02);
    setObfuscatedText_03(inputText_03);
    setObfuscatedText_04(inputText_04);
    setObfuscatedText_05(inputText_05);
    setObfuscatedText_06(inputText_06);
  }
  useEffect(() => {
    onPageLoad();
    const interval = setInterval(() => {
      setTime(prevTime => {
        const newSeconds = prevTime.seconds + 1;
        const newMinutes = prevTime.minutes + Math.floor(newSeconds / 60);
        return {
          minutes: newMinutes,
          seconds: newSeconds % 60
        };
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);
 
  // const buttonNext = () => {
  //   // TODO Modal you lose every progress in this case (yes / no?)
  //   // Switch to next Data
  //   nextData()

  //   setLoading_01(true);
  //   setLoading_02(true);
  //   setLoading_03(true);
  //   setLoading_04(true);
  //   setLoading_05(true);
  //   setLoading_06(true);
    
  //   // Start JSL
  //   startDeidentifikationKi();
  // }
  // const buttonPreviouse = () => {
  //   // TODO Modal you lose every progress in this case (yes / no?)
  //   // Switch to next Data
  //   prevData()

  //   setLoading_01(true);
  //   setLoading_02(true);
  //   setLoading_03(true);
  //   setLoading_04(true);
  //   setLoading_05(true);
  //   setLoading_06(true);

  //   // Start JSL
  //   startDeidentifikationKi();
  // }
  // const buttonFinishNext = async () => {
  //   if (!loading_01 && !loading_02 && !loading_03 && !loading_04 && !loading_05 && !loading_06) {
  //     // Switch to next Data
  //     nextData();

  //     const bodyProcessData = {
  //       importedDataId: importedDataId,
  //       jslId: jslId,
  //       manualAnnotatedEntities: [aiTaggedEntityList_01, aiTaggedEntityList_02, aiTaggedEntityList_03, aiTaggedEntityList_04, aiTaggedEntityList_05, aiTaggedEntityList_06],
  //       result: [obfuscatedText_01, obfuscatedText_02, obfuscatedText_03, obfuscatedText_04, obfuscatedText_05, obfuscatedText_06],
  //       caseTime: [time],
  //     }
  //     console.log(bodyProcessData);
  //     const responseExpressPD = await expressHandler.createProcessedData(bodyProcessData);

  //     setLoading_01(true);
  //     setLoading_02(true);
  //     setLoading_03(true);
  //     setLoading_04(true);
  //     setLoading_05(true);
  //     setLoading_06(true);
  //     setTime({ minutes: 0, seconds: 0 });

  //     // Start JSL
  //     startDeidentifikationKi();
  //   }
  // }
  const buttonFinishReturn = async () => {

    const notFilledTexts = checkFilled();
    if (notFilledTexts !== "") {
      openToastWarning("Nicht alle Felder ausgefüllt", "Feld" + notFilledTexts + " fehlen.");
      return;
    }
    chooseRandomDeidentificationMethod();

    const userId = expressKeycloakHandler.getCookie("user_id");
    const department = expressKeycloakHandler.getCookie("department");

    const bodyProcessData = {
      importedDataId: importedDataId,
      input: [inputText_01, inputText_02, inputText_03, inputText_04, inputText_05, inputText_06],
      aiInference: [],
      postprocessedTxt: [obfuscatedText_01, obfuscatedText_02, obfuscatedText_03, obfuscatedText_04, obfuscatedText_05, obfuscatedText_06],
      caseTime: [time],
      userId: userId,
      department: department,
      aiUsed: false,
    }
    const [responseExpressPD, responseExpressImportData] = await Promise.all([
      expressHandler.createProductionData(bodyProcessData),
      expressHandler.updateImportData(importedDataId, { deidentifiziert: true })
    ]);
    setTime({ minutes: 0, seconds: 0 });

    // Update of Table
    const responseExpressImpData = await expressHandler.getImportData();
    setTableData({ type: "LOAD_DATA_FROM_MONGODB", payload: responseExpressImpData });

    setDeidProzessToggle(false);
  }
  const buttonReturnToFallueberblick = () => {
    setDeidProzessToggle(false);
    chooseRandomDeidentificationMethod();
  }

  const checkFilled = () => {
    let notFilledResults = "";
    if (inputText_01 !== "" && obfuscatedText_01 === "") {
      notFilledResults += " 1";
    }
    if (inputText_02 !== "" && obfuscatedText_02 === "") {
      notFilledResults += " 2";
    }
    if (inputText_03 !== "" && obfuscatedText_03 === "") {
      notFilledResults += " 3";
    }
    if (inputText_04 !== "" && obfuscatedText_04 === "") {
      notFilledResults += " 4";
    }
    if (inputText_05 !== "" && obfuscatedText_05 === "") {
      notFilledResults += " 5";
    }
    if (inputText_06 !== "" && obfuscatedText_06 === "") {
      notFilledResults += " 6";
    }
    return notFilledResults;
  }
  return (
    <MDBox>
      {renderWarning}
      <MDBox display="flex" justifyContent="space-between">
        <MDBox display="flex" gap={1}>
          <MDButton variant="gradient" color="dark" onClick={() => buttonReturnToFallueberblick()} style={{ fontSize:"20px" }}>
            <Icon>backup_table</Icon>
          </MDButton>
          {/* <MDButton variant="gradient" color="dark" onClick={() => buttonPreviouse()} style={{ fontSize:"20px" }}>
            &lt;
          </MDButton>
          <MDButton variant="gradient" color="dark" onClick={() => buttonNext()} style={{ fontSize:"20px" }}>
            &gt;
          </MDButton> */}
          <MDBox display="flex" flexDirection="column" ml={2}>
            <MDTypography variant="h6">{title}</MDTypography>
            <MDTypography variant="subtitle2">Fallnummer: {fallnummer}</MDTypography>
          </MDBox>
          <MDBox ml={5}>
            <MDTypography>Timer: {time.minutes < 10 ? '0' : ''}{time.minutes}:{time.seconds < 10 ? '0' : ''}{time.seconds}</MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" gap={3}>
          <MDButton variant="gradient" color="dark" onClick={() => buttonFinishReturn()}>
            Abschließen und zur Übersicht
          </MDButton>
          {/* <MDButton variant="gradient" color="dark" onClick={() => buttonFinishReturn()}>
            Abschließen Nächster Fall
          </MDButton> */}
        </MDBox>
      </MDBox>
      <MDBox mt={5}>
        <MDTypography variant="h5" style={{ marginBottom:"8px" }}>Fallbeschreibung</MDTypography>
        <Grid container spacing={5} rowSpacing={5}>
          <OrginaltextCard
            inputValue={inputText_01}
            handleTextInputChange={(e) => handleTextInputChange(1, e)}
          />
          <ResultatCard
            resultatValue={obfuscatedText_01}
            handleTextInputChange={(e) => handleTextResultatChange(1, e)}
          />
        </Grid>
      </MDBox>
      <MDBox mt={5}>
        <MDTypography variant="h5" style={{ marginBottom:"8px" }}>Was ist in ihrem Fall nicht gut verlaufen?</MDTypography>
        <Grid container spacing={5} rowSpacing={5}>
          <OrginaltextCard
            inputValue={inputText_02}
            handleTextInputChange={(e) => handleTextInputChange(2, e)}
          />
          <ResultatCard
            resultatValue={obfuscatedText_02}
            handleTextInputChange={(e) => handleTextResultatChange(2, e)}
          />
        </Grid>
      </MDBox>
      <MDBox mt={5}>
        <MDTypography variant="h5" style={{ marginBottom:"8px" }}>Was war in Ihrem Fall ggf. besonders gut/positiv?</MDTypography>
        <Grid container spacing={5} rowSpacing={5}>
          <OrginaltextCard
            inputValue={inputText_03}
            handleTextInputChange={(e) => handleTextInputChange(3, e)}
          />
          <ResultatCard
            resultatValue={obfuscatedText_03}
            handleTextInputChange={(e) => handleTextResultatChange(3, e)}
          />
        </Grid>
      </MDBox>
      <MDBox mt={5}>
        <MDTypography variant="h5" style={{ marginBottom:"8px" }}>Was waren beitragende Faktoren?</MDTypography>
        <Grid container spacing={5} rowSpacing={5}>
          <OrginaltextCard
            inputValue={inputText_04}
            handleTextInputChange={(e) => handleTextInputChange(4, e)}
          />
          <ResultatCard
            resultatValue={obfuscatedText_04}
            handleTextInputChange={(e) => handleTextResultatChange(4, e)}
          />
        </Grid>
      </MDBox>
      <MDBox mt={5}>
        <MDTypography variant="h5" style={{ marginBottom:"8px" }}>Was könnte man aus Ihrem Fall lernen?</MDTypography>
        <Grid container spacing={5} rowSpacing={5}>
          <OrginaltextCard
            inputValue={inputText_05}
            handleTextInputChange={(e) => handleTextInputChange(5, e)}
          />
          <ResultatCard
            resultatValue={obfuscatedText_05}
            handleTextInputChange={(e) => handleTextResultatChange(5, e)}
          />
        </Grid>
      </MDBox>
      <MDBox mt={5}>
        <MDTypography variant="h5" style={{ marginBottom:"8px" }}>Haben sie noch weitere Gedanken zu Ihrem Fall?</MDTypography>
        <Grid container spacing={5} rowSpacing={5}>
          <OrginaltextCard
            inputValue={inputText_06}
            handleTextInputChange={(e) => handleTextInputChange(6, e)}
          />
          <ResultatCard
            resultatValue={obfuscatedText_06}
            handleTextInputChange={(e) => handleTextResultatChange(6, e)}
          />
        </Grid>
      </MDBox>
    </MDBox>
  );
}

export default DeidProzessManuell;
