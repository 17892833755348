import React, { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// Additional components (React Spinner)
import HashLoader from "react-spinners/HashLoader";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

// Own components
import TextLabeling from "examples/TextLabeling";

// Handler
// import ExpressHandler from "handler/expressHandler";

function ObfscationCard({
  text,
  header,
  buttonVisibility,
  buttonThumbsUpDown,
  startObfuscation,
  modelNumber,
  thumbsUpDown
}) {
  const [load, setLoad] = useState(false);

  const start = async () => {
    setLoad(true);
    await startObfuscation(modelNumber);
    setLoad(false);
  }

  const thumbUp = () => {
    buttonThumbsUpDown(modelNumber, 0);
  }

  const thumbDown = () => {
    buttonThumbsUpDown(modelNumber, 1);
  }
  return (
    <Grid item xs={12} md={6} lg={6}>
      <MDBox>
        <Card>
          <MDBox
            display="flex"
            flexDirection="column"
            rowGap={1}
            py={1}
            px={2}
            maxHeight="325px"
            style={{ minHeight: "325px" }}
          >
            <MDBox display="flex" justifyContent="space-between">
              <MDTypography variant="h5">{header}</MDTypography>
              <MDBox display="flex" gap={2}>
                {buttonVisibility && 
                  <MDButton variant="gradient" color="dark" onClick={() => start()}>
                    Start
                  </MDButton>
                }
              </MDBox>
            </MDBox>
            {load ? (
              <MDBox
                borderRadius="lg"
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  marginBottom:10,
                  marginTop:10,
                  height:1000,
                  maxHeight: "100%",
                  overflowY: "auto",
                  border: "1px solid #dee2e6"
                }}
              >
                <HashLoader
                  color="#dee2e6"
                  loading={load}
                  size={50}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </MDBox>
            ) : (
              <MDBox
                borderRadius="lg"
                padding="10px 40px 0px 25px"
                style={{
                  marginBottom:10,
                  marginTop:10,
                  height:1000,
                  maxHeight: "100%",
                  overflowY: "auto",
                  border: "1px solid #dee2e6"
                }}
              >
                {text && (
                  <MDTypography variant="body2">{text}</MDTypography>
                )}
              </MDBox>
            )}
            <MDBox display="flex" gap={1} style={{ left:"auto", right:"30px", top:"auto", bottom:"25px", position:"absolute" }}>
              <ThumbUpIcon
                onClick={thumbUp}
                style={{
                  color:thumbsUpDown[modelNumber][0] ? "#228B22" : "gray",
                  cursor:"pointer"
                }}
              />
              <ThumbDownIcon
                onClick={thumbDown}
                style={{
                  color:thumbsUpDown[modelNumber][1] ? "red" : "gray",
                  cursor:"pointer"
                }}
              />
            </MDBox>
            
          </MDBox>
        </Card>
      </MDBox>
    </Grid>
  );
}

// Setting default values
ObfscationCard.defaultProps = {
  text: "",
  setLlmText: () => {},
  header: "",
  buttonVisibility: false,
  startDeid: () => {},
  updateAnnotations: () => {},
  inputText: "",
  taggedEntities: [],
  tokens: [],
  setLoading: () => {},
  setButtonsVisibility: () => {},
  setObfuscatedTokens: () => {},
  setTaggedEntityList: () => {},
  setTokens: () => {},
  textNumber: 0,
  buttonThumbsUpDown: () => {},
  thumbsUpDown: [],
};

// Typechecking props 
ObfscationCard.propTypes = {
  text: PropTypes.string,
  setLlmText: PropTypes.func,
  header: PropTypes.string,
  buttonVisibility: PropTypes.bool,
  startDeid: PropTypes.func,
  updateAnnotations: PropTypes.func,
  inputText: PropTypes.string,
  taggedEntities: PropTypes.array,
  tokens: PropTypes.array,
  setLoading: PropTypes.func,
  setButtonsVisibility: PropTypes.func,
  setObfuscatedTokens: PropTypes.func,
  setTaggedEntityList: PropTypes.func,
  setTokens: PropTypes.func,
  textNumber: PropTypes.number,
  buttonThumbsUpDown: PropTypes.func,
  thumbsUpDown: PropTypes.array,
};

export default ObfscationCard;
