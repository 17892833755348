class ExpressKeycloakHandler {
  // baseURL = "http://81.89.199.178:5004/api/keycloak";
  baseURL = "https://xp-kc.covid-companion.de/api/keycloak";
  basicUsername = process.env.REACT_APP_BASIC_USERNAME;
  basicPassword = process.env.REACT_APP_BASIC_PASSWORD;
  // login
  async login(body) {
    // expects body={username:String, password:String}
    const credentials = `${this.basicUsername}:${this.basicPassword}`;
    const base64Credentials = btoa(credentials);

    const header = {
      "Content-Type": "application/json",
      "Authorization": `Basic ${base64Credentials}`,
      Accepts: "application/json"
    };
    
    try {
      const response = await fetch(this.baseURL + "/login", {
        method: "POST",
        body: JSON.stringify(body),
        headers: header,
      });
      const json = await response.json();
      if (!response.ok) {
        console.log("response expressKeycloak failed");
      }
      if (response.ok) {
        const body_userinfo = { token: json.access_token };
        const response_userinfo = await fetch(this.baseURL + "/userinfo", {
          method: "POST",
          body: JSON.stringify(body_userinfo),
          headers: header,
        });
        const json_userinfo = await response_userinfo.json();

        const cookiemaxtime = 90000000;
        this.setCookie('lc_access_token', json.access_token, json.expires_in * 1000);
        this.setCookie('lc_refresh_token', json.refresh_token, json.refresh_expires_in * 1000);
        this.setCookie('first_name', json_userinfo.given_name, cookiemaxtime);
        this.setCookie('last_name', json_userinfo.family_name, cookiemaxtime);
        this.setCookie('email', json_userinfo.email, cookiemaxtime);
        this.setCookie('user_id', json_userinfo.id, cookiemaxtime);
        this.setCookie('department', json_userinfo.attributes.department[0], cookiemaxtime);
        return json;
      }
    } catch (error) {
      console.log("Login not working currently", error);
    }
  };

  // introspect
  async introspect(body) {
    // expects body={token:String}
    const credentials = `${this.basicUsername}:${this.basicPassword}`;
    const base64Credentials = btoa(credentials);

    const response = await fetch(this.baseURL + "/introspect", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${base64Credentials}`
      },
    });
    const json = await response.json();
    if (!response.ok) {
      console.log("response expressKeycloak failed");
    }
    if (response.ok) {
      return json;
    }
  };

  // refresh
  async refresh(body) {
    // expects body={token:String}
    const credentials = `${this.basicUsername}:${this.basicPassword}`;
    const base64Credentials = btoa(credentials);

    const response = await fetch(this.baseURL + "/refresh", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${base64Credentials}`
      },
    });
    const json = await response.json();
    if (!response.ok) {
      console.log("response expressKeycloak failed");
    }
    if (response.ok) {
      return json;
    }
  };

  // logout
  async logout(body) {
    // expects body={token:String}
    const credentials = `${this.basicUsername}:${this.basicPassword}`;
    const base64Credentials = btoa(credentials);

    const response = await fetch(this.baseURL + "/logout", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${base64Credentials}`
      },
    });
    const json = await response.json();
    if (!response.ok) {
      console.log("response expressKeycloak failed");
    }
    if (response.ok) {
      return json;
    }
  };

  async checkRefreshToken () {
    try {
      const refresh_token = this.getCookie("lc_refresh_token");
      const introspecs = await this.introspect({ token: refresh_token });
      if (introspecs.active) {
        const refresh = await this.refresh({ token: refresh_token });
        // Save tokens in cookies
        this.setCookie('lc_access_token', refresh.access_token, refresh.expires_in * 1000);
        this.setCookie('lc_refresh_token', refresh.refresh_token, refresh.refresh_expires_in * 1000);
      } else {
        this.naviToLoginPage();
      }
    } catch (error) {
      this.naviToLoginPage();
    }
  };

  // userinfo
  async userinfo() {
    // expects body={token:String}
    const credentials = `${this.basicUsername}:${this.basicPassword}`;
    const base64Credentials = btoa(credentials);

    const token = this.getCookie("lc_access_token");

    const body = {
      token: token
    }

    const response = await fetch(this.baseURL + "/introspect", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${base64Credentials}`
      },
    });
    const json = await response.json();
    if (!response.ok) {
      console.log("response expressKeycloak failed");
    }
    if (response.ok) {
      return json;
    }
  };

  // getTokenCount
  async getTokenCount() {
    // expects body={token:String}
    const credentials = `${this.basicUsername}:${this.basicPassword}`;
    const base64Credentials = btoa(credentials);

    const token = this.getCookie("lc_access_token");
    const userId = this.getCookie("user_id");

    const body = {
      token: token,
      userId: userId
    }

    const response = await fetch(this.baseURL + "/tokenCount", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${base64Credentials}`
      },
    });
    const json = await response.json();
    if (!response.ok) {
      console.log("response expressKeycloak failed");
    }
    if (response.ok) {
      return json;
    }
  };

  // incrementToken
  async incrementToken() {
    // expects body={token:String}
    const credentials = `${this.basicUsername}:${this.basicPassword}`;
    const base64Credentials = btoa(credentials);

    const token = this.getCookie("lc_access_token");
    const userId = this.getCookie("user_id");

    const body = {
      token: token,
      userId: userId
    }

    const response = await fetch(this.baseURL + "/incrementtoken", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${base64Credentials}`
      },
    });
    const json = await response.json();
    if (!response.ok) {
      console.log("response expressKeycloak failed");
    }
    if (response.ok) {
      return json;
    }
  };

  // createUser
  async createUser(body) {
    // expects body={token:String}
    const credentials = `${this.basicUsername}:${this.basicPassword}`;
    const base64Credentials = btoa(credentials);

    const createBody = {
      username: body.username,
      firstName: body.firstName,
      lastName: body.lastName,
      email: body.email,
      emailVerified: true,
      enabled: true,
      credentials:[
          {
          value: body.password,
          temporary: false
          }
      ]
    }
    // console.log(createBody);

    const response = await fetch(this.baseURL + "/createuser", {
      method: "POST",
      body: JSON.stringify(createBody),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Basic ${base64Credentials}`
      },
    });
    console.log(response);
    // const json = await response.json();
    if (!response.ok) {
      console.log("response expressKeycloak failed");
      return "response expressKeycloak failed";
    }
    if (response.ok ) {
      const json_response = await response.json();

      if (response.status == 200) {
        return json_response.message;
      }
      return json_response.errorMessage;
    }
  };
  
  async logoutDeleteToken () {
    try {
      const refresh_token = this.getCookie("lc_refresh_token");
      const responseLogout = await this.logout({ token: refresh_token });
      // Delete cookies
      this.deleteCookie('lc_access_token');
      this.deleteCookie('lc_refresh_token');
      this.deleteCookie('first_name');
      this.deleteCookie('last_name');
      this.deleteCookie('email');
      this.deleteCookie('username');
      this.deleteCookie('user_id');
      this.deleteCookie('department');
      this.naviToLoginPage();
      
    } catch (error) {
      // this.naviToLoginPage();
      console.log("fail");
    }
  }

  /**
   * Sets a cookie with the given name and value.
   * @param {string} name - The name of the cookie.
   * @param {string} value - The value of the cookie.
   * @param {number} [millis] - Optional. The number of milliseconds until the cookie expires.
   * Example:
   * const authentificationManager = new AuthentificationManager();
   * authentificationManager.setCookie("GesamtZiel",GesamtZiel,30000000);
   */
  setCookie(name, value, millis) {
    const expirationDate = new Date();
    if (millis) {
      expirationDate.setTime(expirationDate.getTime() + millis);
    }
    const cookieString = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`; // cloud version
    // const cookieString = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/; SameSite=None; Secure`; //localhost version
    document.cookie = cookieString;
  }

  getCookie(name) {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split('=');
      if (cookieName && cookieName === name) {
        return cookieValue;
      }
    }
    if(name === "user_id" || name === "dhi_auth_token" || name === "dhi_refresh_token" || name === "spital_Name") {
      this.naviToLoginPage()
    }
    return null;
  }

  /**
   * Deletes a cookie with the given name.
   * @param {string} name - The name of the cookie to delete.
  */
  deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  /**
   * Redirects to the login URL if the user is not already there.
  */
  naviToLoginPage() {
    // Check if the current URL is already the base URL
    const isOnBaseURL = window.location.pathname === "/authentication/sign-in";
    if (!isOnBaseURL) {
      // Redirect to the base URL
      window.location.href = window.location.origin + "/authentication/sign-in";
    }
  }
}

export default ExpressKeycloakHandler;